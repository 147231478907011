import Vue from "vue";
import lodash from "lodash";

const state = {
  totalRows: 0,
  companies: [],
  companiesMeta: {
    countries: [],
    services: [],
    positions: [],
    users: [],
    roles: [],
  },
};

const mutations = {
  SET_COMPANIES(state, companies) {
    Vue.set(state, "companies", companies);
  },
  UPDATE_COMPANIES(state, companyData) {
    const index = state.companies.findIndex(({ id }) => companyData.id === id);
    state.companies.splice(index, 1, companyData);
  },
  SET_COMPANIES_META(state, companiesMeta) {
    Vue.set(state, "companiesMeta", companiesMeta);
  },
  SET_TOTAL_ROWS(state, totalPaginationRows) {
    Vue.set(state, "totalRows", totalPaginationRows);
  },
  SET_COUNTRY_DATA(state, country) {
    state.companiesMeta.countries = lodash.cloneDeep(
      country.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      })
    );
  },
  SET_SERVICE_DATA(state, service) {
    state.companiesMeta.services = lodash.cloneDeep(
      service.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      })
    );
  },
  SET_POSITION_DATA(state, position) {
    state.companiesMeta.positions = lodash.cloneDeep(
      position.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      })
    );
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
