<template>
  <span v-if="hasPermission">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "AuthAction",
  props: {
    permissionName: {
      type: String,
      required: false,
    },
  },
  computed: {
    explodePermissionString() {
      return this.permissionName.split(",");
    },
    hasPermission() {
      let hasPermission = false;
      this.explodePermissionString.forEach((permission) => {
        if (this.$store.getters["userPermissions"].includes(permission))
          hasPermission = true;
      });
      return hasPermission;
    },
  },
};
</script>
