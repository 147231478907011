import lodash from "lodash";

const state = {
  leadBoardData: {
    title: null,
    companyId: null,
    description: null,
    apiEnabled: false,
    isDisabled: false,
    facebookFormId: null,
    facebookPageId: null,
    facebookFormTitle: null,
    users: [],
  },
};

const mutations = {
  SET_LEAD_BOARD_DATA(state, leadBoard) {
    state.leadBoardData = lodash.cloneDeep(leadBoard);
  },
  UPDATE_LEAD_BOARD_MEMBERS(state, users) {
    state.leadBoardData.users = lodash.cloneDeep(users);
  },
  CHANGE_LEAD_BOARD_FIELD(state, { fieldName, fieldValue }) {
    state.leadBoardData[fieldName] = fieldValue;
  },
  SET_FACEBOOK_FORM_TITLE(state, title) {
    state.leadBoardData.facebookFormTitle = title;
  },
  SET_COMPANY_ID(state, id) {
    state.leadBoardData.companyId = id;
  },
  RESET_LEAD_BOARD_DATA(state) {
    state.leadBoardData = lodash.cloneDeep({
      title: null,
      companyId: null,
      description: null,
      apiEnabled: false,
      isDisabled: false,
      facebookFormId: null,
      facebookPageId: null,
      facebookFormTitle: null,
      users: [],
    });
  },
};

const actions = {};

const getters = {
  getLeadBoardFieldData: (state) => (fieldName) => {
    return state.leadBoardData[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
