<template>
  <main class="d-flex flex-wrap mb-5 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <auth-action
        permission-name="users.read"
        class="d-flex flex-wrap col-12 px-0"
      >
        <search-bar
          title="Create new users, customize user permissions and remove users"
          button-title="Create user"
          :selected-items="selectedUsers"
          :is-loading="isLoading"
          multiple-delete-button-id="deleteSelectedUser"
          permission-name="users.write"
          delete-permission-name="users.delete"
          @createNew="isNewUser"
          @changeStatus="fetchUsers"
          @searchChanged="searchChanged($event)"
          @deleteSelectedItem="deleteSelectedUser($event)"
        >
        </search-bar>
      </auth-action>

      <users-table
        ref="usersTable"
        @isNewUser="isNewUser($event)"
        @selectedUsers="selectUsers($event)"
        @deleteUser="deleteSelectedUser($event)"
      ></users-table>
    </b-overlay>

    <user-crud-sidebar :is-new="isNew" @refreshUsers="fetchUsers" />
    <!-- START OF PAGINATION -->
    <div class="d-flex align-items-end flex-column col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="lg"
        class="mb-0 mt-auto p-3"
        col-12
        @input="fetchUsers"
      ></b-pagination>
    </div>
    <!-- END OF PAGINATION -->
  </main>
</template>

<script>
import UsersTable from "@/components/users/UsersTable";
import SearchBar from "@/components/gadgets/SearchBar";
import UserCrudSidebar from "@/components/users/user/UserCrudSidebar";

export default {
  name: "UsersDashboard",
  components: {
    UserCrudSidebar,
    SearchBar,
    UsersTable,
  },
  data() {
    return {
      isNew: true,
      selectedUsers: [],
      currentPage: 1,
      search: "",
      fetching: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchUsers();
    this.fetchMetaData();
  },
  computed: {
    users() {
      return this.$store.state.users.users;
    },
    status() {
      return this.$store.state.status;
    },
    totalRows() {
      return this.$store.state.users.totalRows;
    },
    perPage() {
      return this.$store.state.perPage;
    },
  },
  watch: {
    status() {
      this.currentPage = 1;
    },
  },
  methods: {
    searchChanged(value) {
      this.search = value;
      this.currentPage = 1;
      this.fetchUsers();
    },
    async fetchUsers() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/users?page=${
            this.currentPage
          }&perPage=${this.perPage}${
            this.status !== "Active" ? "&isDisabled=1" : ""
          }${this.search ? `&search=${this.search}` : ""}`
        );
        this.$store.commit("users/SET_TOTAL_ROWS", res.data.total);
        this.$store.commit("users/SET_USERS", res.data.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "User fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
        this.$refs.usersTable
          ? (this.$refs.usersTable.$children[0].$el.scrollTop = 0)
          : null;
      }
    },
    async fetchMetaData() {
      try {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/users/meta`
        );
        this.$store.commit("users/SET_JOB_POSITIONS_DATA", res.data.positions);
        this.$store.commit("users/SET_PERMISSIONS_DATA", res.data.permissions);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "User fetching failed",
          variant: "danger",
          solid: true,
        });
      }
    },
    selectUsers(event) {
      this.$set(this, "selectedUsers", event);
    },
    isNewUser(event = true) {
      this.isNew = event;
      this.$root.$emit("bv::toggle::collapse", "userCrud");
    },

    async deleteSelectedUser(user) {
      const checkIfArray = Array.isArray(user);
      let conf = confirm(
        `Are you sure to delete ${
          checkIfArray
            ? `multiple users (${user.length})`
            : `user: ${user.email}`
        }`
      );
      if (conf) {
        this.isLoading = true;
        try {
          await this.deleteRequest(checkIfArray, user);

          this.$bvToast.toast("Delete was successful", {
            title: "Delete was successful",
            variant: "success",
            solid: true,
          });
          await this.fetchUsers();
          this.$set(this, "selectedUsers", []);
          this.$set(this.$refs.usersTable, "selectedUsersId", []);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.response.data.message, {
            title: "User delete failed",
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteRequest(checkIfArray, user) {
      if (!checkIfArray) {
        return await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/users/${user.id}`
        );
      } else {
        return await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/users/bulk-delete`,
          { users: user }
        );
      }
    },
  },
};
</script>
