<template>
  <button
    :id="id"
    :ref="id"
    class="d-flex flex-wrap justify-content-center align-items-center p-3 btn btn-primary text-uppercase"
    @click="$emit('click')"
    :disabled="isLoading"
  >
    <slot name="button-icon"></slot>
    {{ title }}
    <div v-if="isLoading" class="ml-2">
      <b-spinner small></b-spinner>
    </div>
  </button>
</template>

<script>
export default {
  name: "FuButton",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
