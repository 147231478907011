<template>
  <login-template
    title="forgot password"
    subtitle="Please enter your email to request new password"
  >
    <template #body>
      <validation-observer tag="div" ref="forgotPassword" class="w-100 mt-3">
        <validation-provider
          tag="div"
          name="Email"
          rules="required|email|max:45"
          v-slot="{ errors }"
        >
          <label class="w-100 font-weight-bold">
            <span><i class="fas fa-envelope fa-1x pr-2"></i>Email</span><br />
            <input
              v-model="email"
              type="email"
              name="email"
              maxlength="45"
              required
              placeholder="Email"
              :disabled="isLoading"
              class="fu-login__input"
              @keyup.enter="requestNewPassword"
            />
          </label>
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </validation-observer>
    </template>

    <template #footer>
      <fu-button
        title="forgot password"
        :id="'forgotButton'"
        :isLoading="isLoading"
        @click="requestNewPassword"
      />
      <router-link to="/login" class="mt-2 fu-login__link"
        >Back to login</router-link
      >
    </template>
  </login-template>
</template>

<script>
import LoginTemplate from "@/components/login/LoginTemplate";

export default {
  name: "ForgotPassword",
  components: { LoginTemplate },
  data() {
    return {
      email: null,
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
  },
  mounted() {
    if (
      this.user &&
      !(
        this.$store.state.loggedUser.has2fa &&
        !this.$store.state.loggedUser.secretSubmitted
      )
    )
      this.$router.push({ name: "UserDashboard" });
  },
  methods: {
    requestNewPassword() {
      this.$refs.forgotPassword.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/auth/reset-password`,
              {
                email: this.email,
              }
            );
            this.$bvToast.toast(res.data.message, {
              title: "Request was successful",
              variant: "success",
              solid: true,
            });
          } catch (error) {
            this.$bvToast.toast(error.response.data.message, {
              title: "Forgot password failed",
              variant: "danger",
              solid: true,
            });
            console.error(error);
          } finally {
            this.isLoading = false;
            this.email = null;
          }
        }
      });
    },
  },
};
</script>
