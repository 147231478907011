<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-2">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :items="unassignedDeals"
      :fields="fields"
    >
      <template #cell(action)="{ item }">
        <button
          class="fu-button__transparent px-0"
          @click="viewDealDetails(item)"
        >
          <i
            v-b-tooltip.hover
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
            class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
          ></i>
        </button>
      </template>
      <template #cell(createdAt)="{ value }">
        {{ dateFormatter(value) }}
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "UnassignedDealsTable",
  data() {
    return {
      fields: [
        {
          key: "contactPerson",
          sortable: false,
          label: "Contact person",
        },
        {
          key: "column.leadBoard.title",
          sortable: true,
          label: "Lead board",
        },
        {
          key: "column.leadBoard.companyName",
          sortable: true,
          label: "Company",
        },
        {
          key: "createdAt",
          sortable: true,
          label: "Created",
        },
        {
          key: "action",
          sortable: false,
          label: "Edit",
        },
      ],
    };
  },
  computed: {
    unassignedDeals() {
      return this.$store.state.analytics.unassignedDeals;
    },
  },
  methods: {
    async viewDealDetails(deal) {
      await this.$router.push({
        name: "LeadBoardDeal",
        params: { id: deal.column.leadBoard.id, dealId: deal.id },
      });
    },
    dateFormatter(date) {
      return `${new Date(date).toLocaleDateString()} ${date
        .split(" ")[1]
        .slice(0, 5)}`;
    },
  },
};
</script>

<style scoped></style>
