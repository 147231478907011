import Vue from "vue";

const state = {
  questionnaires: [],
};

const mutations = {
  SET_QUESTIONNAIRES_DATA(state, data) {
    Vue.set(state, "questionnaires", data);
  },
  UPDATE_QUESTIONNAIRES_DATA(state, { data, id }) {
    if (id) {
      const index = state.questionnaires.findIndex((value) => value.id === id);
      state.questionnaires.splice(index, 1, data);
    } else {
      state.questionnaires.push(data);
    }
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
