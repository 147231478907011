<template>
  <div :class="formClass">
    <validation-provider
      tag="div"
      name="contact person"
      rules="required"
      v-slot="{ errors }"
      class="col-12 px-0 mb-0"
    >
      <span v-if="iframe" class="w-100 font-weight-bold"
        >Kontaktna oseba *</span
      >
      <span v-else class="w-100 font-weight-bold">Contact person *</span>
      <br />

      <input
        v-model="contactPerson"
        type="text"
        name="contact person"
        required
        placeholder="Contact person"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      tag="div"
      :rules="{ email: true, max: 70, required: !phone }"
      v-slot="{ errors }"
      class="col-lg-6 col-md-12 px-0 pr-lg-3 pr-0 mt-3"
    >
      <span v-if="iframe" class="w-100 font-weight-bold">E-pošta *</span>
      <span v-else class="w-100 font-weight-bold">Email *</span>

      <br />
      <input
        v-model="email"
        type="email"
        name="email"
        placeholder="Email"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      tag="div"
      name="phone"
      :rules="{ max: 30, regex: /^[+]?[0-9]*$/, required: !email }"
      v-slot="{ errors }"
      class="col-lg-6 col-md-12 px-0 pl-lg-3 pl-0 mt-3"
    >
      <span v-if="iframe" class="w-100 font-weight-bold"
        >Telefonska številka *</span
      >
      <span v-else class="w-100 font-weight-bold">Phone number *</span> <br />
      <input
        v-model="phone"
        type="text"
        name="phone"
        placeholder="Phone number"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="organization"
      rules="required"
      v-slot="{ errors }"
      class="col-12 px-0 mt-3"
    >
      <span class="w-100 font-weight-bold">Organization *</span>
      <br />
      <multiselect
        v-model="clientId"
        :options="organization"
        :loading="isLoading"
        label="name"
        track-by="id"
        placeholder="Organization"
        class="multiselect mt-2"
        :class="{ multiselect__disabled: isLoading }"
        :disabled="isLoading"
        @search-change="searchValue = $event"
      >
        <template slot="placeholder"> Organization</template>

        <template slot="option" slot-scope="props">
          <div
            class="d-flex flex-wrap align-items-center justify-content-between"
          >
            <span>{{ props.option.name }}</span>
            <button
              class="multiselect__option-delete"
              :disabled="isLoading"
              @click.stop="deleteOrganization(props.option)"
            >
              <i class="far fa-trash-alt mr-2"></i>
            </button>
          </div>
        </template>

        <template slot="noResult">
          <div class="d-flex flex-wrap align-items-center">
            <span>No position found: "{{ searchValue }}" </span>
            <button
              class="btn btn-primary ml-3"
              :disabled="isLoading"
              @click="addNewOrganization()"
            >
              <span>Add new<i class="fal fa-plus text-white ml-3"></i></span>
            </button>
          </div>
        </template>
      </multiselect>
      <span class="text-danger">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="title"
      v-slot="{ errors }"
      class="col-12 px-0 mt-3"
    >
      <span class="w-100 font-weight-bold">Title</span><br />
      <input
        v-model="title"
        type="text"
        name="title"
        placeholder="Title"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="value"
      :rules="{
        regex: /^[0-9]{0,9}(\.[0-9]{1,2})?$/,
        required: !!currencyId,
      }"
      v-slot="{ errors }"
      class="col-6 px-0 pr-3 mt-3"
    >
      <span class="w-100 font-weight-bold">Value</span><br />
      <input
        v-model="value"
        type="text"
        name="value"
        placeholder="Value"
        class="fu-login__input"
        :disabled="isLoading"
      />
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="currency"
      :rules="value ? 'required' : ''"
      v-slot="{ errors }"
      class="col-6 px-0 pl-3 mt-3"
    >
      <span class="w-100 font-weight-bold">Currency</span><br />
      <select
        v-model="currencyId"
        class="fu-login__input fu-select"
        :class="{ 'fu-select__disabled': isLoading }"
      >
        <option
          v-for="option in currencies"
          :key="option.id"
          :value="option.id"
        >
          {{ option.name }}
        </option>
      </select>
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="column"
      rules="required"
      v-slot="{ errors }"
      class="col-6 px-0 pr-3 mt-3"
    >
      <span class="w-100 font-weight-bold">Column</span><br />
      <select
        v-model="columnId"
        class="fu-login__input fu-select"
        :class="{ 'fu-select__disabled': isLoading }"
      >
        <option v-for="option in columns" :key="option.id" :value="option.id">
          {{ option.name }}
        </option>
      </select>
      <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
    </validation-provider>

    <validation-provider
      v-if="!iframe"
      tag="div"
      name="deal owner"
      v-slot="{ errors }"
      class="col-6 px-0 pl-3 mt-3"
    >
      <span class="w-100 font-weight-bold">Deal owner</span>
      <br />
      <multiselect
        v-model="dealOwner"
        :options="users"
        :custom-label="memberFullName"
        track-by="id"
        placeholder="Deal owner"
        class="multiselect mt-2"
        @search-change="searchValue = $event"
      >
        <template slot="placeholder"> Deal owner</template>

        <template slot="option" slot-scope="props">
          <span class="kt-select-item">{{
            `${props.option.firstName} ${props.option.lastName}`
          }}</span>
        </template>

        <template slot="noResult">
          <span>No position found: "{{ searchValue }}" </span>
        </template>
      </multiselect>
      <span class="text-danger">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "LeadBoardDealForm",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    formClass: {
      type: String,
      required: true,
    },
    iframe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchValue: "",
    };
  },
  computed: {
    organization() {
      return this.$store.state.columnDeal.dealMeta.clients;
    },
    currencies() {
      return this.$store.state.columnDeal.dealMeta.currencies;
    },
    columns() {
      return this.$store.state.columnDeal.dealMeta.columns;
    },
    users() {
      return this.$store.state.columnDeal.dealMeta.users;
    },
    contactPerson: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"](
          "contactPerson"
        );
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "contactPerson",
          fieldValue,
        });
      },
    },
    email: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("email");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "email",
          fieldValue,
        });
      },
    },
    phone: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("phone");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "phone",
          fieldValue,
        });
      },
    },
    clientId: {
      get() {
        const selectedId = this.$store.getters["columnDeal/getDealFieldData"](
          "clientId"
        );
        return this.organization.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "clientId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    dealOwner: {
      get() {
        const selectedId = this.$store.getters["columnDeal/getDealFieldData"](
          "ownerId"
        );
        return this.users.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "ownerId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    title: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("title");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "title",
          fieldValue,
        });
      },
    },
    value: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("value");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "value",
          fieldValue,
        });
      },
    },
    currencyId: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("currencyId");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "currencyId",
          fieldValue,
        });
      },
    },
    columnId: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("columnId");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "columnId",
          fieldValue,
        });
      },
    },
  },
  methods: {
    memberFullName({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    async deleteOrganization(option) {
      let conf = confirm(`Are you sure to delete ${option.name} organization?`);
      if (conf) {
        this.isLoading = true;
        try {
          const res = await this.$axios.delete(
            `${process.env.VUE_APP_API_URL}/api/company-clients/${option.id}`
          );
          this.$store.commit("columnDeal/SET_ORGANIZATION_DATA", res.data);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.message, {
            title: `Organization delete failed`,
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },

    async addNewOrganization() {
      this.isLoading = true;
      try {
        const searchVal = this.searchValue;
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/company-clients`,
          {
            name: searchVal,
            company_id: this.$store.state.columns.leadBoard.company.id,
          }
        );

        await this.$store.commit("columnDeal/SET_ORGANIZATION_DATA", res.data);
        this.clientId = res.data.find(({ name }) => name === searchVal);
        this.$bvToast.toast("Organization created successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.searchValue = "";
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: `Organization creation failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
