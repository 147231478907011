<template>
  <main class="d-flex flex-wrap mb-5 mt-3 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <b-row class="w-100">
        <b-col class="col-6">
          <h3>Unassigned deals</h3>
          <unassigned-deals-table />
        </b-col>
        <b-col class="col-6">
          <h3>Companies without lead boards</h3>
          <companies-without-lead-boards-table />
        </b-col>
      </b-row>
      <b-row class="w-100 border-top pt-2">
        <b-col class="col-5">
          <h3>Lead Forwarding</h3>
          <lead-forwarding-analytics />
        </b-col>

        <b-col class="col-7 border-left">
          <b-row class="col-12 d-flex justify-content-center mb-2">
            <date-range-picker></date-range-picker>
          </b-row>
          <b-row>
            <b-col class="border-top border-right">
              <h3>Created deals</h3>
              <created-deals
            /></b-col>
            <b-col class="border-left border-top">
              <h3>Submitted Questionnaires</h3>
              <submitted-questionnaires-by-user />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
  </main>
</template>

<script>
import UnassignedDealsTable from "@/components/analytics/UnassignedDealsTable";
import CompaniesWithoutLeadBoardsTable from "@/components/analytics/CompaniesWithoutLeadBoardsTable";
import LeadForwardingAnalytics from "@/components/analytics/LeadForwardingAnalytics";
import CreatedDeals from "@/components/analytics/CreatedDeals";
import SubmittedQuestionnairesByUser from "@/components/analytics/SubmittedQuestionnairesByUser";
import DateRangePicker from "@/components/analytics/DateRangePicker";

export default {
  name: "AnalyticsDashboard",
  components: {
    UnassignedDealsTable,
    CompaniesWithoutLeadBoardsTable,
    LeadForwardingAnalytics,
    CreatedDeals,
    SubmittedQuestionnairesByUser,
    DateRangePicker,
  },
  data() {
    return {
      fetching: false,
    };
  },
  created() {
    this.fetchAnalytics();
  },
  methods: {
    async fetchAnalytics() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/analytics`
        );
        this.$store.commit(
          "analytics/SET_COMPANIES_WITHOUT_LEAD_BOARDS",
          res.data.companiesWithoutLeadBoards
        );
        this.$store.commit(
          "analytics/SET_UNASSIGNED_DEALS",
          res.data.unassignedDeals
        );
        this.$store.commit(
          "analytics/SET_LEAD_FORWARDS_DATA",
          res.data.leadForwardsData
        );
        this.$store.commit(
          "analytics/SET_DEALS_BY_CREATOR",
          res.data.dealsByCreator
        );
        this.$store.commit(
          "analytics/SET_DEALS_BY_LEAD_BOARD",
          res.data.dealsByLeadBoard
        );

        this.$store.commit(
          "analytics/SET_SUBMITTED_QUESTIONNAIRE_BY_USER",
          res.data.submittedQuestionnairesByUser
        );
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Analytics fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style scoped></style>
