<template>
  <div class="col-lg-6 col-6 px-4">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Deal activity</h5>

    <lead-board-deal-activity-todo-list></lead-board-deal-activity-todo-list>

    <lead-board-deal-activity-comments></lead-board-deal-activity-comments>
  </div>
</template>

<script>
import LeadBoardDealActivityTodoList from "@/components/lead-board/deal/activity/LeadBoardDealActivityTodoList";
import LeadBoardDealActivityComments from "@/components/lead-board/deal/activity/LeadBoardDealActivityComments";
export default {
  name: "LeadBoardDealActivity",
  components: { LeadBoardDealActivityComments, LeadBoardDealActivityTodoList },
  props: {
    dealId: {
      type: Number,
    },
  },
};
</script>
