<template>
  <main>
    <b-overlay :show="isFetching" class="d-flex flex-wrap col-12">
      <lead-board-deal-header
        :is-loading="isLoading"
        @saveChanges="saveChanges"
      ></lead-board-deal-header>

      <validation-observer
        ref="dealDetailsDashboardForm"
        class="d-flex flex-wrap justify-content-between col-12 px-0"
      >
        <lead-board-deal-details></lead-board-deal-details>

        <lead-board-deal-activity :deal-id="dealId"></lead-board-deal-activity>

        <lead-board-deal-questionnaire></lead-board-deal-questionnaire>
      </validation-observer>
    </b-overlay>
  </main>
</template>

<script>
import LeadBoardDealDetails from "@/components/lead-board/deal/LeadBoardDealDetails";
import LeadBoardDealActivity from "@/components/lead-board/deal/LeadBoardDealActivity";
import LeadBoardDealQuestionnaire from "@/components/lead-board/deal/LeadBoardDealQuestionnaire";
import LeadBoardDealHeader from "@/components/lead-board/deal/LeadBoardDealHeader";
export default {
  name: "LeadBoardDeal",
  components: {
    LeadBoardDealHeader,
    LeadBoardDealQuestionnaire,
    LeadBoardDealActivity,
    LeadBoardDealDetails,
  },
  data() {
    return {
      isLoading: false,
      isFetching: false,
    };
  },
  computed: {
    leadBoardId() {
      return this.$store.state.columns.leadBoard.id;
    },
    dealId() {
      return this.$store.state.columnDeal.deal.id;
    },
  },
  beforeMount() {
    this.fetchDealMeta();
  },
  beforeDestroy() {
    this.$store.commit("columnDeal/RESET_DEAL_DATA");
  },
  methods: {
    async saveChanges() {
      this.$refs.dealDetailsDashboardForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            await this.$store.commit(
              "columnDeal/UPDATE_ACTIVE_QUESTIONNAIRE",
              this.$store.state.columnDeal.questionnaireData
            );
            const res = await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.dealId}`,
              this.$store.state.columnDeal.deal
            );
            await this.$store.commit("columnDeal/SET_DEAL_DATA", res.data);
            this.$bvToast.toast("Deal was updated", {
              title: `Success`,
              variant: "success",
              solid: true,
            });
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Updating lead deal details failed", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    async fetchDealMeta() {
      try {
        this.isFetching = true;
        const [
          leadBoardData,
          leadBoardMeta,
          questionnaires,
          deal,
        ] = await Promise.all([
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/meta`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/questionnaires`
          ),
          await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.$route.params.dealId}`
          ),
        ]);
        if (
          !leadBoardMeta.data.users.filter(
            ({ id }) => id === this.$store.state.loggedUser.id
          ).length
        )
          leadBoardMeta.data.users.push(this.$store.state.loggedUser);
        this.$store.commit("columnDeal/SET_DEAL_META", leadBoardMeta.data);
        leadBoardMeta.data.users.push(
          { id: -2, firstName: "Unassigned", lastName: "" },
          { id: -1, firstName: "All", lastName: "" }
        );
        this.$store.commit(
          "columnDeal/SET_DEAL_OWNERS",
          leadBoardMeta.data.users
        );
        this.$store.commit("columns/SET_LEAD_BOARD_DATA", leadBoardData.data);
        this.$store.commit("columnDeal/SET_DEAL_DATA", deal.data);
        this.$store.commit(
          "questionnaire/SET_QUESTIONNAIRES_DATA",
          questionnaires.data
        );
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: "Fetching lead board columns failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isFetching = false;
      }
    },
  },
};
</script>
