<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-5">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :sort-compare-options="{ numeric: true, ignorePunctuation: true }"
      :items="companies"
      :fields="fields"
      :per-page="itemsPerPage"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allSelectedOnPage"
          @change="selectAll"
        ></b-form-checkbox>
      </template>

      <template #cell(select)="{ item }">
        <b-form-checkbox
          :checked="selectedCompaniesId.includes(item.id)"
          @change="selectCompany(item.id)"
        ></b-form-checkbox>
      </template>

      <template #cell(actions)="{ item }">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center w-100"
        >
          <auth-action permission-name="companies.read">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editCompany(item.id, true)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                class="far fa-eye fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action permission-name="companies.write">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editCompany(item.id, false)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
          <auth-action permission-name="companies.delete">
            <button
              class="fu-button__transparent px-0"
              @click="deleteCompany(item)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                class="far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import AuthAction from "@/components/gadgets/AuthAction";
export default {
  name: "CompaniesTable",
  components: { AuthAction },
  data() {
    return {
      allSelectedOnPage: false,
      fields: [
        {
          key: "select",
          sortable: false,
          label: "",
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "taxNumber",
          sortable: false,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "phone",
          sortable: false,
        },
        {
          key: "averageOrderValue",
          sortable: true,
        },
        {
          key: "service",
          sortable: true,
          label: "Service",
          formatter: (value, key, item) => {
            return item.service ? item.service.name : "";
          },
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "actions",
          sortable: false,
          label: "",
        },
      ],
      selectedCompaniesId: [],
    };
  },
  watch: {
    companies() {
      this.checkIfAllSelected();
    },
    selectedCompaniesId(value) {
      this.$emit("selectedCompanies", value);
      this.checkIfAllSelected();
    },
  },
  computed: {
    companies() {
      return this.$store.state.companies.companies;
    },
    itemsPerPage() {
      return this.$store.state.perPage;
    },
  },
  methods: {
    checkIfAllSelected() {
      const companiesIds = this.companies.map(({ id }) => id);
      let count = 0;
      companiesIds.forEach((companyId) => {
        if (this.selectedCompaniesId.includes(companyId)) count++;
      });

      this.allSelectedOnPage =
        companiesIds.length === count && companiesIds.length !== 0;
    },
    selectCompany(companyId) {
      if (!this.selectedCompaniesId.includes(companyId)) {
        this.selectedCompaniesId.push(companyId);
      } else {
        const index = this.selectedCompaniesId.indexOf(companyId);
        if (index > -1) this.selectedCompaniesId.splice(index, 1);
      }
    },
    selectAll(value) {
      this.companies.forEach((company) => {
        if (value) {
          if (!this.selectedCompaniesId.includes(company.id)) {
            this.selectedCompaniesId.push(company.id);
          }
        } else {
          const index = this.selectedCompaniesId.indexOf(company.id);
          if (index > -1) this.selectedCompaniesId.splice(index, 1);
        }
      });
    },
    editCompany(selectedId, onlyRead) {
      const company = this.companies.find(({ id }) => id === selectedId);
      this.$store.commit("company/SET_COMPANY_DATA", company);
      this.$emit("onlyReadCompany", onlyRead);

      this.$emit(
        "enabledApiConnections",
        this.$store.getters["company/hasEnabledApi"]
      );
      this.$root.$emit("bv::toggle::collapse", "editCompanySidebar");
    },
    deleteCompany(selectedCompany) {
      this.$emit("deleteCompany", selectedCompany);
    },
  },
};
</script>
