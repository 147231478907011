<template>
  <b-modal
    id="twoFactorModal"
    size="lg"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="resetAuthModal"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-center col-12">
        <login-header
          :show-logo="false"
          title="Two-Factor Authentication"
          class="mx-auto"
        ></login-header>
        <button
          @click="$bvModal.hide('twoFactorModal')"
          class="fu-modal__header-close-button"
        >
          <i class="fal fa-times fa-2x"></i>
        </button>
      </div>
    </template>

    <div class="d-flex flex-wrap justify-content-center text-center">
      <h5 class="pb-4">Enter the code generated by your authenticator app</h5>

      <qr-code-scan v-if="firstTime" class="mb-4"></qr-code-scan>

      <qr-code-input
        @secret="secret = $event"
        @submit="verifyAuthentication"
      ></qr-code-input>
    </div>

    <template #modal-footer>
      <fu-button
        ref="buttonComponent"
        title="verify"
        :id="'2fa-button'"
        :is-loading="isLoading"
        :disabled="secret.length < 6"
        @click="verifyAuthentication"
      ></fu-button>
    </template>
  </b-modal>
</template>

<script>
import LoginHeader from "@/components/login/LoginHeader";
import QrCodeScan from "@/components/login/modals/two-factor-auth/QrCodeScan";
import QrCodeInput from "@/components/login/modals/two-factor-auth/QrCodeInput";

export default {
  name: "TwoFactorAuth",
  components: { QrCodeInput, QrCodeScan, LoginHeader },
  data() {
    return {
      isLoading: false,
      secret: "",
    };
  },
  computed: {
    firstTime() {
      return this.$store.state.generatedQrCode !== "";
    },
    user() {
      return this.$store.state.loggedUser;
    },
  },
  methods: {
    async verifyAuthentication() {
      if (this.secret.length < 6) return;
      try {
        this.isLoading = true;
        await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/auth/confirm-qr`,
          {
            secret: this.secret,
          }
        );
        this.$bvModal.hide("twoFactorModal");
        this.$store.commit("CHANGE_USER_SECRET_SUBMITTED", true);
        this.goToUserFirstPage();
      } catch (error) {
        this.$bvToast.toast(
          error.response ? error.response.data.message : "failed",
          {
            title: "Two-Factor authentication failed",
            variant: "danger",
            solid: true,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
    goToUserFirstPage() {
      this.$router.push({ name: "NoPermissions" });
    },
    async resetAuthModal() {
      this.secret = "";
      this.isLoading = false;
      if (!this.user.secretSubmitted) {
        this.$store.commit("SET_USER_DATA", null);
      }
    },
  },
};
</script>
