import lodash from "lodash";

const state = {
  companyData: {
    id: null,
    name: "",
    address: "",
    taxNumber: null,
    countryId: null,
    zipCode: null,
    averageOrderValue: null,
    email: "",
    phone: "",
    serviceId: null,
    leadPrice: null,
    isDisabled: false,
    facebookId: null,
    facebookSecret: null,
    facebookToken: null,
    mailerliteToken: null,
    contactPeople: [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        position: {},
        positionId: null,
      },
    ],
  },
};

const mutations = {
  SET_COMPANY_DATA(state, company) {
    state.companyData = lodash.cloneDeep(company);
  },
  CHANGE_COMPANY_FIELD(state, { fieldName, fieldValue }) {
    state.companyData[fieldName] = fieldValue;
  },
  UPDATE_COMPANY_CONTACTS(state, contacts) {
    state.companyData.contactPeople = lodash.cloneDeep(contacts);
  },
  RESET_COMPANY_DATA(state) {
    state.companyData = {
      id: null,
      name: "",
      address: "",
      taxNumber: null,
      countryId: null,
      zipCode: null,
      averageOrderValue: null,
      email: "",
      phone: "",
      serviceId: null,
      leadPrice: null,
      isDisabled: false,
      facebookId: null,
      facebookSecret: null,
      facebookToken: null,
      mailerliteToken: null,
      contactPeople: [
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          position: {},
          positionId: null,
        },
      ],
    };
  },
  RESET_API_VALUES(state) {
    state.companyData.facebookId = null;
    state.companyData.facebookSecret = null;
    state.companyData.mailerliteToken = null;
    state.companyData.facebookToken = null;
  },
};

const actions = {};

const getters = {
  getCompanyFieldData: (state) => (fieldName) => {
    return state.companyData[fieldName];
  },
  hasEnabledApi: (state) => {
    return (
      state.companyData.facebookSecret !== null ||
      state.companyData.facebookId !== null ||
      state.companyData.facebookToken !== null ||
      state.companyData.mailerliteToken !== null
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
