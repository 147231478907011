<template>
  <div>
    <h3 class="text-center">Date range</h3>
    <date-picker
      v-model="range"
      mode="date"
      :model-config="modelConfig"
      is-range
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div>
          <input :value="inputValue.start" v-on="inputEvents.start" />

          <input :value="inputValue.end" v-on="inputEvents.end" />
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "DateRangePicker",
  components: {
    DatePicker,
  },
  data() {
    return {
      range: {
        end: new Date(),
        start: new Date(),
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  watch: {
    range: {
      handler: function () {
        this.fetchDateRangeMeta();
      },
      deep: true,
    },
  },
  methods: {
    async fetchDateRangeMeta() {
      const res = await this.$axios.get(
        `${process.env.VUE_APP_API_URL}/api/analytics?from=` +
          this.range.start +
          "&to=" +
          this.range.end
      );
      this.$store.commit(
        "analytics/SET_DEALS_BY_CREATOR",
        res.data.dealsByCreator
      );
      this.$store.commit(
        "analytics/SET_DEALS_BY_LEAD_BOARD",
        res.data.dealsByLeadBoard
      );

      this.$store.commit(
        "analytics/SET_SUBMITTED_QUESTIONNAIRE_BY_USER",
        res.data.submittedQuestionnairesByUser
      );
    },
  },
};
</script>

<style scoped></style>
