<template>
  <div class="qr-modal__qr-code">
    <img :src="`data:image/svg+xml;base64,${generatedQrCode}`" />
  </div>
</template>

<script>
export default {
  name: "QrCodeScan",
  computed: {
    generatedQrCode() {
      return this.$store.state.generatedQrCode;
    },
  },
};
</script>
