<template>
  <div
    class="d-flex justify-content-center align-items-center text-center fu-empty-page"
  >
    <h1>
      It looks like you don't have the required permissions to view this
      content.<br />Please contact your administrator for more information
    </h1>
  </div>
</template>

<script>
export default {
  name: "NoPermissions",
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
  },
  mounted() {
    if (!this.user) this.$router.push({ name: "Login" });
    else if (this.user.permissions.length)
      this.$router.push({ name: "UserDashboard" });
  },
};
</script>
