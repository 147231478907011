<template>
  <b-sidebar
    id="userCrud"
    ref="userCrud"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__user"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-column"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
  >
    <template #header>
      <span>{{ changeTitle }} user</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer tag="div" ref="userCrudForm" class="w-100 mt-3">
      <validation-provider
        tag="label"
        name="first name"
        rules="required|alpha_spaces|max:45"
        v-slot="{ errors }"
        class="w-100"
      >
        <span class="font-weight-bold">First name *</span><br />
        <input
          id="kt-input-name"
          v-model="firstName"
          type="text"
          name="first name"
          required
          placeholder="First name"
          class="fu-login__input"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="last name"
        rules="required|alpha_spaces|max:45"
        v-slot="{ errors }"
        class="w-100 mt-2"
      >
        <span class="font-weight-bold">Last name *</span><br />
        <input
          id="kt-input-surname"
          v-model="lastName"
          type="text"
          name="last name"
          required
          placeholder="Last name"
          class="fu-login__input"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="email"
        rules="required|email|max:60"
        v-slot="{ errors }"
        class="w-100 mt-2"
      >
        <span class="font-weight-bold">Email *</span><br />
        <input
          id="kt-input-email"
          v-model="email"
          type="email"
          class="fu-login__input"
          name="email"
          required
          placeholder="Email"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="phone"
        :rules="{ max: 30, regex: /^[+]?[0-9]*$/ }"
        v-slot="{ errors }"
        class="w-100 mt-2"
      >
        <span class="font-weight-bold">Phone number</span><br />
        <input
          id="kt-input-phone"
          v-model="phone"
          type="text"
          maxlength="30"
          name="phone"
          placeholder="Phone number"
          class="fu-login__input"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="div"
        name="job position"
        rules="required"
        v-slot="{ errors }"
        class="w-100 mt-2"
      >
        <span class="font-weight-bold">Job position *</span>
        <multiselect
          v-model="position"
          :options="jobPositions"
          :loading="isLoading"
          placeholder="Job position"
          class="multiselect mt-2"
          label="name"
          track-by="name"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder"> Job position </template>

          <template slot="option" slot-scope="props">
            <div
              class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
            >
              <span>{{ props.option.name }}</span>
              <button
                class="multiselect__option-delete kt-button-remove-position"
                :disabled="isLoading"
                @click.stop="deleteJobPosition(props.option)"
              >
                <i class="far fa-trash-alt mr-2"></i>
              </button>
            </div>
          </template>

          <template slot="noResult">
            <div class="d-flex flex-wrap align-items-center">
              <span>No job position found: "{{ searchValue }}" </span>
              <button
                id="kt-button-add-position"
                class="btn btn-primary ml-3"
                :disabled="isLoading"
                @click="addNewJobPosition"
              >
                <span>Add new<i class="fal fa-plus text-white ml-3"></i></span>
              </button>
            </div>
          </template>
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>

    <div class="d-flex align-items-center mt-4">
      <span class="col-2 p-0 font-weight-bold">Archived: </span>
      <b-form-checkbox
        id="kt-checkbox-archived"
        v-model="isDisabled"
        name="is-archived"
        size="lg"
        switch
        class="ml-3 mb-3 fu-checkbox"
      >
      </b-form-checkbox>
      <span class="mb-0 ml-5">{{ isDisabled ? "Active" : "Disabled" }}</span>
    </div>

    <div class="d-flex align-items-center mt-4">
      <span class="col-2 p-0 font-weight-bold">2FA: </span>
      <b-form-checkbox
        id="kt-checkbox-2fa"
        v-model="has2fa"
        name="has-2fa"
        size="lg"
        switch
        class="ml-3 mb-3 fu-checkbox"
      >
      </b-form-checkbox>
      <span class="mb-0 ml-5">{{ has2fa ? "Active" : "Disabled" }}</span>
    </div>

    <div class="mt-4">
      <h6 class="font-weight-bold mb-0">Permissions:</h6>
      <div
        v-for="key in Object.keys(availablePermissions)"
        :key="key"
        class="d-flex flex-wrap justify-content-between col-12 p-0"
      >
        <h6 class="w-100 mb-2 mt-4 text-capitalize">{{ key }}:</h6>
        <b-form-checkbox
          v-for="permission in availablePermissions[key]"
          :key="permission.id"
          :id="`kt-checkbox-${key}-permission-${permission.name}`"
          :checked="permissions.includes(permission.id)"
          :disabled="isPermissionDisabled(key, permission)"
          class="text-capitalize"
          @change="selectPermission(key, permission.id)"
          ><span class="fu-permission__label">{{
            permission.name
          }}</span></b-form-checkbox
        >
      </div>
    </div>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="saveUser"
          :disabled="isLoading"
        >
          <span
            ><i :class="`far fa-${isNew ? 'plus' : 'edit'} mr-2`"></i
            >{{ isNew ? "Create" : "Save" }}</span
          >
        </button>
        <button
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "UserCrudSidebar",
  props: {
    isNew: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchValue: "",
      isLoading: false,
    };
  },
  computed: {
    status() {
      return this.$store.state.status;
    },
    jobPositions() {
      return this.$store.state.users.jobPositions;
    },
    changeTitle() {
      return this.isNew ? "Create" : "Edit";
    },
    availablePermissions() {
      const keys = this.$store.state.users.permissions
        .map(({ name }) => {
          return name.split(".")[0];
        })
        .filter((value, index, array) => array.indexOf(value) === index);
      const permissions = this.$store.state.users.permissions;
      const availablePermissions = {};

      keys.forEach((key) => {
        availablePermissions[key] = permissions
          .map((permission) => {
            const name = permission.name.split(".");
            if (name[0] === key) {
              return {
                name: name[1],
                id: permission.id,
              };
            }
          })
          .filter((permission) => permission);
      });

      return availablePermissions;
    },
    firstName: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("firstName");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "firstName",
          fieldValue,
        });
      },
    },
    lastName: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("lastName");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "lastName",
          fieldValue,
        });
      },
    },
    email: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("email");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "email",
          fieldValue,
        });
      },
    },
    phone: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("phone");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "phone",
          fieldValue,
        });
      },
    },
    position: {
      get() {
        const selectedId = this.$store.getters["user/getUserFieldData"](
          "positionId"
        );
        return this.jobPositions.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "positionId",
          fieldValue: fieldValue.id,
        });
      },
    },
    isDisabled: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("isDisabled");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "isDisabled",
          fieldValue,
        });
      },
    },
    has2fa: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("has2fa");
      },
      set(fieldValue) {
        this.$store.commit("user/CHANGE_USER_FIELD", {
          fieldName: "has2fa",
          fieldValue,
        });
      },
    },
    permissions: {
      get() {
        return this.$store.getters["user/getUserFieldData"]("permissions");
      },
      set(value) {
        this.$store.commit("user/CHANGE_USER_PERMISSIONS", value);
      },
    },
  },
  methods: {
    async deleteJobPosition(option) {
      let conf = confirm(`Are you sure to delete ${option.name} job position?`);
      if (conf) {
        this.isLoading = true;
        try {
          const res = await this.$axios.delete(
            `${process.env.VUE_APP_API_URL}/api/job-positions/${option.id}`
          );
          this.$store.commit("users/SET_JOB_POSITIONS_DATA", res.data);
          this.$bvToast.toast(res.statusText, {
            title: "Job position was successfully deleted",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          console.error(error);
          this.errorToast(error.message);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async addNewJobPosition() {
      this.isLoading = true;
      try {
        const res = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/job-positions`,
          {
            name: this.searchValue,
          }
        );
        this.position = res.data.find(({ name }) => name === this.searchValue);
        this.searchValue = "";
        this.$store.commit("users/SET_JOB_POSITIONS_DATA", res.data);
      } catch (error) {
        console.error(error);
        this.errorToast(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    isPermissionDisabled(key, permission) {
      const keyReadPermission = this.availablePermissions[key].find(
        ({ name }) => name === "read"
      );
      return (
        !this.permissions.includes(keyReadPermission.id) &&
        permission.id !== keyReadPermission.id
      );
    },
    isReadPermission(key, permissionId) {
      return (
        this.availablePermissions[key].find(({ id }) => id === permissionId)
          .name === "read"
      );
    },
    selectPermission(key, id) {
      if (this.permissions.includes(id)) {
        if (this.isReadPermission(key, id)) {
          const ids = this.availablePermissions[key].map(
            (permission) => permission.id
          );
          ids.forEach((permissionId) => {
            const index = this.permissions.indexOf(permissionId);
            if (index > -1) this.permissions.splice(index, 1);
          });
        } else {
          const index = this.permissions.indexOf(id);
          this.permissions.splice(index, 1);
        }
      } else this.permissions.push(id);
    },
    closeSideBar() {
      this.$refs.userCrud.hide();
      this.value = [];
      this.searchValue = "";
      this.$store.commit("user/RESET_USER_DATA");
      this.$refs.userCrudForm.reset();
    },
    errorToast(message) {
      this.$bvToast.toast(message, {
        title: "User action failed",
        variant: "danger",
        solid: true,
      });
    },
    saveUser() {
      this.$refs.userCrudForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const userData = this.$store.state.user.userData;
            let index = userData.permissions.findIndex((value) => value === 0);
            if (index !== -1) userData.permissions.splice(index, 1);

            if (this.isNew) {
              await this.$axios.post(
                `${process.env.VUE_APP_API_URL}/api/users`,
                userData
              );
              this.$emit("refreshUsers");
            } else {
              const res = await this.$axios.put(
                `${process.env.VUE_APP_API_URL}/api/users/${userData.id}`,
                userData
              );
              if (
                (res.data.isDisabled && this.status === "Active") ||
                (this.status === "Archived" && !res.data.isDisabled)
              )
                this.$emit("refreshUsers");
              else this.$store.commit("users/UPDATE_USERS", res.data);
            }
            this.$bvToast.toast("Saved successfully", {
              title: `User was successfully ${
                this.isNew ? "created" : "updated"
              }`,
              variant: "success",
              solid: true,
            });
            this.$refs.userCrud.hide();
          } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
              let err = error.response.data.errors;
              for (const key in err) {
                this.errorToast(err[key][0]);
              }
            } else {
              this.errorToast(error.response.data.message);
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
