<template>
  <b-sidebar
    id="editCompanySidebar"
    ref="editCompanySidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__company"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-wrap align-items-start"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
    @shown="sidebarShown"
  >
    <template #header>
      <span>{{ onlyReadCompany ? "View" : "Edit" }} Company</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer
      ref="editCompanyForm"
      class="d-flex flex-wrap col-12 mt-3 px-0"
    >
      <validation-provider
        tag="label"
        name="company name"
        rules="required"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pr-lg-3 pr-0"
      >
        <span class="w-100 font-weight-bold">Company name *</span>
        <br />
        <input
          id="kt-input-company-name-edit"
          v-model="name"
          type="text"
          name="company name"
          required
          placeholder="Company name"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        rules="required"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pl-lg-3 pl-0 mt-lg-0 mt-3"
      >
        <span class="w-100 font-weight-bold">Address *</span>
        <br />
        <input
          id="kt-input-company-address-edit"
          v-model="address"
          type="text"
          name="address"
          required
          placeholder="Address"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="tax number"
        class="col-lg-6 col-md-12 px-0 pr-lg-3 pr-0 mt-3"
      >
        <span class="w-100 font-weight-bold">Tax Number</span>
        <br />
        <input
          id="kt-input-company-tax-number-edit"
          v-model="taxNumber"
          type="text"
          name="tax number"
          placeholder="Tax Number"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
      </validation-provider>

      <div class="d-flex flex-wrap col-lg-6 col-12 px-0 pl-lg-3 pl-0 mt-3">
        <validation-provider
          tag="div"
          name="country"
          rules="required"
          v-slot="{ errors }"
          class="col-6 px-0"
        >
          <label class="col-6 px-0 font-weight-bold">Country *</label>
          <multiselect
            v-model="country"
            :options="allCountries"
            :loading="isLoading"
            label="name"
            track-by="name"
            placeholder="Country"
            class="multiselect"
            :class="{ multiselect__disabled: onlyReadCompany }"
            :disabled="onlyReadCompany"
            @search-change="searchValue = $event"
          >
            <template slot="placeholder"> Country </template>

            <template slot="option" slot-scope="props">
              <div
                class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
              >
                <span>{{ props.option.name }}</span>
                <button
                  class="multiselect__option-delete kt-button-remove-country-edit"
                  :disabled="isLoading || onlyReadCompany"
                  @click.stop="deleteOption(props.option, 'countries')"
                >
                  <i class="far fa-trash-alt mr-2"></i>
                </button>
              </div>
            </template>

            <template slot="noResult">
              <div class="d-flex flex-wrap align-items-center">
                <span>No country found: "{{ searchValue }}" </span>
                <button
                  id="kt-button-add-country-edit"
                  class="btn btn-primary ml-3"
                  :disabled="isLoading || onlyReadCompany"
                  @click="addNewOption('countries')"
                >
                  <span
                    >Add new<i class="fal fa-plus text-white ml-3"></i
                  ></span>
                </button>
              </div>
            </template>
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="label"
          name="zip code"
          rules="max:30"
          v-slot="{ errors }"
          class="col-6 px-0 pl-3"
        >
          <span class="w-100 font-weight-bold">Zip code</span>
          <br />
          <input
            id="kt-input-company-zip-code-edit"
            v-model="zipCode"
            type="text"
            name="zip code"
            placeholder="Zip Code"
            class="fu-login__input"
            :disabled="onlyReadCompany"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </div>

      <validation-provider
        tag="label"
        rules="required|email|max:70"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pr-lg-3 pr-0 mt-lg-3 mt-3"
      >
        <span class="w-100 font-weight-bold">Email *</span>
        <br />
        <input
          id="kt-input-company-email-edit"
          v-model="email"
          type="email"
          name="email"
          required
          placeholder="Email"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="average order"
        :rules="{
          regex: /^[0-9]{0,9}(\.[0-9]{1,2})?$/,
        }"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pl-lg-3 pl-0 mt-3"
      >
        <span class="w-100 font-weight-bold">Average order</span>
        <br />
        <input
          id="kt-input-company-avg-order-edit"
          v-model="averageOrderValue"
          type="text"
          name="average order"
          placeholder="Average order"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="phone"
        :rules="{ max: 30, regex: /^[+]?[0-9]*$/ }"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pr-lg-3 pr-0 mt-3"
      >
        <span class="w-100 font-weight-bold">Phone number</span>
        <br />
        <input
          id="kt-input-company-phone-edit"
          v-model="phone"
          type="text"
          name="phone"
          placeholder="Phone number"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="div"
        name="service"
        class="col-lg-6 col-md-12 px-0 pl-lg-3 mt-3"
      >
        <label class="col-6 px-0 font-weight-bold">Sold service</label>
        <multiselect
          v-model="service"
          :options="allServices"
          :loading="isLoading"
          label="name"
          track-by="name"
          placeholder="Sold service"
          class="multiselect"
          :class="{ multiselect__disabled: onlyReadCompany }"
          :disabled="onlyReadCompany"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder"> Sold service </template>

          <template slot="option" slot-scope="props">
            <div
              class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
            >
              <span>{{ props.option.name }}</span>
              <button
                class="multiselect__option-delete kt-button-remove-service-edit"
                :disabled="isLoading || onlyReadCompany"
                @click.stop="deleteOption(props.option, 'company-services')"
              >
                <i class="far fa-trash-alt mr-2"></i>
              </button>
            </div>
          </template>

          <template slot="noResult">
            <div class="d-flex flex-wrap align-items-center">
              <span>No service found: "{{ searchValue }}" </span>
              <button
                id="kt-button-add-service-edit"
                class="btn btn-primary ml-3"
                :disabled="isLoading || onlyReadCompany"
                @click="addNewOption('company-services')"
              >
                <span>Add new<i class="fal fa-plus text-white ml-3"></i></span>
              </button>
            </div>
          </template>
        </multiselect>
      </validation-provider>

      <validation-provider
        tag="label"
        name="lead price"
        :rules="{
          regex: /^[0-9]{0,9}(\.[0-9]{1,2})?$/,
        }"
        v-slot="{ errors }"
        class="col-lg-6 col-md-12 px-0 pr-lg-3 mt-3"
      >
        <span class="w-100 font-weight-bold">Lead price</span>
        <br />
        <input
          id="kt-input-company-lead-price-edit"
          v-model="leadPrice"
          type="text"
          name="lead price"
          placeholder="Lead price"
          class="fu-login__input"
          :disabled="onlyReadCompany"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <div
        class="d-flex align-items-center col-6 mt-lg-5 mt-3 pl-lg-3 pl-0 px-0"
      >
        <span class="col-lg-2 col-3 p-0">Archived: </span>
        <b-form-checkbox
          id="kt-checkbox-company-archived-edit"
          v-model="isDisabled"
          name="is-archived"
          size="lg"
          switch
          class="ml-5 mb-3 fu-checkbox"
          :disabled="onlyReadCompany"
        >
        </b-form-checkbox>
        <span class="mb-0 ml-5" :data-kt-checkbox-value="isDisabled">{{
          isDisabled ? "Active" : "Disabled"
        }}</span>
      </div>

      <div class="d-flex flex-wrap col-12 px-0 mt-4 fu-border__enable-api">
        <b-form-checkbox
          id="kt-checkbox-company-enabled-api-edit"
          :data-kt-checkbox-api-value="enabledApiConnection"
          :checked="enabledApiConnection"
          class="text-capitalize col-12 mt-4"
          :disabled="onlyReadCompany"
          @change="enableApiConnection"
          >enable API connections</b-form-checkbox
        >

        <validation-provider
          tag="label"
          name="client id"
          :rules="enabledApiConnection ? 'required' : ''"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pr-lg-3 pr-0 mt-3"
        >
          <span class="w-100 font-weight-bold"
            ><i class="fab fa-facebook-square mr-2"></i>Client-id *</span
          ><br />
          <input
            id="kt-input-api-client-id-edit"
            v-model="facebookId"
            type="text"
            name="client_id"
            placeholder="Client-id"
            class="fu-login__input"
            :disabled="!enabledApiConnection || onlyReadCompany"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="label"
          name="client secret"
          :rules="enabledApiConnection ? 'required' : ''"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pl-lg-3 pl-0 mt-3"
        >
          <span class="w-100 font-weight-bold"
            ><i class="fab fa-facebook-square mr-2"></i>Client secret *</span
          ><br />
          <input
            id="kt-input-company-fb-secret-edit"
            type="text"
            v-model="facebookSecret"
            name="client_secret"
            placeholder="Client secret"
            class="fu-login__input"
            :disabled="!enabledApiConnection || onlyReadCompany"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="label"
          name="facebook token"
          :rules="enabledApiConnection ? 'required' : ''"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pr-lg-3 pr-0 mt-3"
        >
          <span clasS="w-100 font-weight-bold"
            ><i class="fab fa-facebook-square mr-2"></i>Facebook token *</span
          ><br />
          <input
            id="kt-input-company-fb-token-edit"
            v-model="facebookToken"
            type="text"
            name="facebook token"
            placeholder="Facebook token"
            class="fu-login__input"
            :disabled="!enabledApiConnection || onlyReadCompany"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="label"
          name="mailerlite key"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pl-lg-3 pl-0 mt-3 mb-4"
        >
          <span class="w-100 font-weight-bold"
            ><i class="far fa-envelope mr-2"></i>Mailerlite API key</span
          ><br />
          <input
            id="kt-input-company-mail-token-edit"
            v-model="mailerliteToken"
            type="text"
            name="mailerlite_key"
            placeholder="Mailerlite key"
            class="fu-login__input"
            :disabled="!enabledApiConnection || onlyReadCompany"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </div>

      <div class="d-flex flex-wrap col-12 px-0 mt-4">
        <h6>Contacts</h6>

        <div
          v-for="(item, index) in contacts"
          :key="index"
          class="d-flex flex-wrap col-12 px-0 mt-3 fu-border__lightgrey"
        >
          <validation-provider
            tag="label"
            :name="`Contact ${index + 1}: First name`"
            rules="required"
            class="col-6 px-0 pr-3 pr-0"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Contact {{ index + 1 }}: First name *</span
            >
            <br />
            <input
              v-model="item.firstName"
              type="text"
              name="first name"
              placeholder="First name"
              class="fu-login__input kt-input-company-contact-firstname-edit"
              :disabled="isLoading || onlyReadCompany"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="label"
            :name="`Contact ${index + 1}: Last name`"
            rules="required"
            class="col-6 px-0 pl-lg-3 pl-0 mt-0"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Contact {{ index + 1 }}: Last name *</span
            >
            <br />
            <input
              v-model="item.lastName"
              type="text"
              name="last name"
              placeholder="Last name"
              class="fu-login__input kt-input-company-contact-lastname-edit"
              :disabled="isLoading || onlyReadCompany"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="label"
            :name="`Contact ${index + 1}: Email`"
            rules="required|email"
            class="col-lg-6 col-12 px-0 pr-lg-3 pr-0 mt-2"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Contact {{ index + 1 }}: Email *</span
            >
            <br />
            <input
              v-model="item.email"
              type="email"
              name="email"
              placeholder="Email"
              class="fu-login__input kt-input-company-contact-email-edit"
              :disabled="isLoading || onlyReadCompany"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="label"
            :name="`Contact ${index + 1}: Phone`"
            :rules="{ max: 30, regex: /^[+]?[0-9]*$/ }"
            class="col-lg-3 col-6 px-0 pl-lg-3 pl-0 mt-2"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Contact {{ index + 1 }}: Phone</span
            >
            <br />
            <input
              v-model="item.phone"
              type="text"
              name="phone"
              placeholder="Phone"
              class="fu-login__input kt-input-company-contact-phone-edit"
              :disabled="isLoading || onlyReadCompany"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="div"
            :name="`Contact ${index + 1}: Position`"
            rules="required"
            v-slot="{ errors }"
            class="col-lg-3 col-6 px-0 pl-lg-3 pl-3 mt-2 kt-div-contact-position"
          >
            <label class="col-12 px-0 font-weight-bold"
              >Contact {{ index + 1 }}: Position *</label
            >
            <multiselect
              v-model="item.position"
              :options="positions"
              :loading="isLoading"
              label="name"
              track-by="id"
              placeholder="Position"
              class="multiselect kt-div-input"
              :class="{ multiselect__disabled: onlyReadCompany || isLoading }"
              :disabled="onlyReadCompany"
              @search-change="searchValue = $event"
            >
              <template slot="placeholder"> Position </template>

              <template slot="option" slot-scope="props">
                <div
                  class="d-flex flex-wrap align-items-center justify-content-between kt-select-item"
                >
                  <span>{{ props.option.name }}</span>
                  <button
                    class="multiselect__option-delete kt-button-remove-contact-position-edit"
                    :disabled="isLoading || onlyReadCompany"
                    @click.stop="deleteOption(props.option, 'job-positions')"
                  >
                    <i class="far fa-trash-alt mr-2"></i>
                  </button>
                </div>
              </template>

              <template slot="noResult">
                <div class="d-flex flex-wrap align-items-center">
                  <span>No position found: "{{ searchValue }}" </span>
                  <button
                    class="btn btn-primary ml-3 kt-button-add-contact-position-edit"
                    :disabled="isLoading || onlyReadCompany"
                    @click="addNewOption('job-positions', index)"
                  >
                    <span
                      >Add new<i class="fal fa-plus text-white ml-3"></i
                    ></span>
                  </button>
                </div>
              </template>
            </multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>

          <button
            v-if="!onlyReadCompany"
            class="mb-3 mt-3 multiselect__option-delete kt-button-remove-company-contact-edit"
            :disabled="isLoading || onlyReadCompany || contacts.length === 1"
            @click="removeContact(index)"
          >
            <span><i class="far fa-trash-alt mr-2"></i>Delete</span>
          </button>
        </div>

        <div
          v-if="!onlyReadCompany"
          class="d-flex justify-content-end col-12 px-0 my-3"
        >
          <button
            id="kt-button-add-company-contact-edit"
            class="fu-contact__button"
            @click="addNewContact"
            :disabled="isLoading || onlyReadCompany"
          >
            <span><i class="far fa-plus mr-2"></i>Add contact</span>
          </button>
        </div>
      </div>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          v-if="!onlyReadCompany"
          id="kt-button-company-submit-edit"
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="editCompany"
          :disabled="onlyReadCompany || isLoading"
        >
          <span><i class="far fa-edit mr-2"></i>Save</span>
        </button>
        <button
          id="kt-button-company-submit-close-edit"
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span
            ><i class="far fa-times mr-2"></i
            >{{ !onlyReadCompany ? "Cancel" : "Close" }}</span
          >
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "EditCompanySidebar",
  props: {
    onlyReadCompany: {
      type: Boolean,
      default: false,
    },
    enabledApiProp: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      enabledApiConnection: this.enabledApiProp,
      isLoading: false,
      searchValue: "",
      contacts: [
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          positionId: null,
        },
      ],
      updateCompanyContacts: null,
    };
  },
  watch: {
    enabledApiProp() {
      this.enabledApiConnection = this.enabledApiProp;
    },
    contacts: {
      deep: true,
      handler(value) {
        this.updateCompanyContacts(value);
      },
    },
  },
  created() {
    this.updateCompanyContacts = this.$lodash.debounce((value) => {
      const selectedContacts = this.$lodash.cloneDeep(value).map((contact) => {
        contact.position
          ? (contact.positionId = contact.position.id)
          : (contact.positionId = null);
        return contact;
      });
      this.$store.commit("company/UPDATE_COMPANY_CONTACTS", selectedContacts);
    }, 500);
  },
  computed: {
    status() {
      return this.$store.state.status;
    },
    allCountries() {
      return this.$store.state.companies.companiesMeta.countries;
    },
    allServices() {
      return this.$store.state.companies.companiesMeta.services;
    },
    positions() {
      return this.$store.state.companies.companiesMeta.positions;
    },
    name: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("name");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "name",
          fieldValue,
        });
      },
    },
    address: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("address");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "address",
          fieldValue,
        });
      },
    },
    taxNumber: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("taxNumber");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "taxNumber",
          fieldValue,
        });
      },
    },
    country: {
      get() {
        const selectedId = this.$store.getters["company/getCompanyFieldData"](
          "countryId"
        );
        return this.allCountries.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "countryId",
          fieldValue: fieldValue.id,
        });
      },
    },
    zipCode: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("zipCode");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "zipCode",
          fieldValue,
        });
      },
    },
    email: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("email");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "email",
          fieldValue,
        });
      },
    },
    averageOrderValue: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"](
          "averageOrderValue"
        );
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "averageOrderValue",
          fieldValue,
        });
      },
    },
    phone: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("phone");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "phone",
          fieldValue,
        });
      },
    },
    service: {
      get() {
        const selectedId = this.$store.getters["company/getCompanyFieldData"](
          "serviceId"
        );
        return this.allServices.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "serviceId",
          fieldValue: fieldValue.id,
        });
      },
    },
    leadPrice: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("leadPrice");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "leadPrice",
          fieldValue,
        });
      },
    },
    isDisabled: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("isDisabled");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "isDisabled",
          fieldValue,
        });
      },
    },
    facebookId: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"]("facebookId");
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "facebookId",
          fieldValue,
        });
      },
    },
    facebookSecret: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"](
          "facebookSecret"
        );
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "facebookSecret",
          fieldValue,
        });
      },
    },
    facebookToken: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"](
          "facebookToken"
        );
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "facebookToken",
          fieldValue,
        });
      },
    },
    mailerliteToken: {
      get() {
        return this.$store.getters["company/getCompanyFieldData"](
          "mailerliteToken"
        );
      },
      set(fieldValue) {
        this.$store.commit("company/CHANGE_COMPANY_FIELD", {
          fieldName: "mailerliteToken",
          fieldValue,
        });
      },
    },
  },
  methods: {
    addNewContact() {
      if (!this.onlyReadCompany) {
        this.contacts.push({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          positionId: null,
        });
      }
    },
    removeContact(index) {
      if (!this.onlyReadCompany && this.contacts.length > 1) {
        const conf = confirm("Do you want to delete this contact?");
        if (conf) this.contacts.splice(index, 1);
      }
    },
    enableApiConnection() {
      this.enabledApiConnection = !this.enabledApiConnection;
      this.$store.commit("company/RESET_API_VALUES");
    },
    sidebarShown() {
      this.contacts = this.$lodash.cloneDeep(
        this.$store.state.company.companyData.contactPeople
      );
    },
    closeSideBar() {
      this.$refs.editCompanySidebar.hide();
      this.$store.commit("company/RESET_COMPANY_DATA");
      this.enabledApiConnection = this.enabledApiProp;
      this.contacts = this.$lodash.cloneDeep([
        {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          positionId: null,
        },
      ]);
      this.$refs.editCompanyForm.reset();
    },
    errorToast(message) {
      this.$bvToast.toast(message, {
        title: "Update company failed",
        variant: "danger",
        solid: true,
      });
    },

    async deleteOption(option, endpoint) {
      let conf = confirm(
        `Are you sure to delete ${option.name} ${
          endpoint === "countries" ? "country" : "service"
        }?`
      );
      if (conf && !this.onlyReadCompany) {
        this.isLoading = true;
        try {
          const res = await this.$axios.delete(
            `${process.env.VUE_APP_API_URL}/api/${endpoint}/${option.id}`
          );
          switch (endpoint) {
            case "countries":
              this.$store.commit("companies/SET_COUNTRY_DATA", res.data);
              break;
            case "company-services":
              this.$store.commit("companies/SET_SERVICE_DATA", res.data);
              break;
            default:
              this.$store.commit("companies/SET_POSITION_DATA", res.data);
          }
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.message, {
            title: `${
              endpoint === "countries" ? "Countries" : "Services"
            } delete failed`,
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async addNewOption(endpoint, index = 0) {
      this.isLoading = true;
      try {
        if (!this.onlyReadCompany) {
          const res = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}/api/${endpoint} `,
            {
              name: this.searchValue,
            }
          );
          switch (endpoint) {
            case "countries":
              {
                this.country = res.data.find(
                  ({ name }) => name === this.searchValue
                );
                this.$store.commit("companies/SET_COUNTRY_DATA", res.data);
              }
              break;
            case "company-services":
              {
                this.service = res.data.find(
                  ({ name }) => name === this.searchValue
                );
                this.$store.commit("companies/SET_SERVICE_DATA", res.data);
              }
              break;
            default: {
              this.contacts[index].position = res.data.find(
                ({ name }) => name === this.searchValue
              );
              this.$store.commit("companies/SET_POSITION_DATA", res.data);
            }
          }
          this.searchValue = "";
        }
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.message, {
          title: `${
            endpoint === "countries" ? "Countries" : "Services"
          } creation failed`,
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },

    editCompany() {
      this.$refs.editCompanyForm.validate().then(async (success) => {
        if (success && !this.onlyReadCompany) {
          this.isLoading = true;
          try {
            const companyData = this.$store.state.company.companyData;
            const res = await this.$axios.put(
              `${process.env.VUE_APP_API_URL}/api/companies/${companyData.id}`,
              companyData
            );

            if (
              (res.data.isDisabled && this.status === "Active") ||
              (this.status === "Archived" && !res.data.isDisabled)
            )
              this.$emit("refreshCompanies");
            else this.$store.commit("companies/UPDATE_COMPANIES", res.data);

            this.$bvToast.toast("Success", {
              title: `Company was successfully updated`,
              variant: "success",
              solid: true,
            });
            this.$refs.editCompanySidebar.hide();
          } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
              let err = error.response.data.errors;
              for (const key in err) {
                this.errorToast(err[key][0]);
              }
            } else {
              this.errorToast(error.response.data.message);
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
