import Vue from "vue";
import lodash from "lodash";
import { put } from "@/helpers/crud";

const state = {
  columns: [],
  selectedColumn: {},
  leadBoard: {},
};

const mutations = {
  SET_LEAD_BOARD_COLUMNS(state, columns) {
    Vue.set(state, "columns", columns);
  },
  SET_LEAD_BOARD_DATA(state, leadBoard) {
    Vue.set(state, "leadBoard", leadBoard);
  },
  SET_SELECTED_COLUMN(state, column) {
    state.selectedColumn = lodash.cloneDeep(column);
  },
  ADD_LEAD_BOARD_COLUMN(state, column) {
    state.columns.push(column);
  },
  UPDATE_COLUMN_DEALS(state, { index, deal }) {
    state.columns[index].deals.push(deal);
  },
  UPDATE_COLUMN_RULES(state, { index, rules }) {
    state.columns[index].rules = rules;
  },
  CHANGE_LEAD_BOARD_COLUMN_POSITION(state, { newPosition, column }) {
    let columnIndex = state.columns.findIndex(
      (value) => value.id === column.id
    );
    let asideColumnIndex = state.columns.findIndex(
      (value) => value.position === newPosition
    );
    state.columns[asideColumnIndex].position = column.position;
    state.columns[columnIndex].position = newPosition;

    const temp = state.columns[columnIndex];
    state.columns[columnIndex] = state.columns[asideColumnIndex];
    state.columns[asideColumnIndex] = temp;
  },
  CHANGE_LEAD_BOARD_COLUMN_TITLE(state, { title, columnId }) {
    state.columns.map((value) => {
      if (value.id === columnId) value.name = title;
    });
  },
  DELETE_LEAD_BOARD_COLUMN(state, columnId) {
    let columnIndex = state.columns.findIndex((value) => value.id === columnId);
    if (columnIndex + 1 !== state.columns.length) {
      state.columns.forEach((element, index) => {
        if (index > columnIndex) element.position--;
      });
    }
    state.columns.splice(columnIndex, 1);
  },
};

const actions = {
  async updateColumnTitle(
    { commit },
    { boardId, columnId, newTitle, position }
  ) {
    try {
      await put(
        process.env.VUE_APP_API_URL +
          `/api/lead-boards/${boardId}/columns/${columnId}`,
        {
          name: newTitle,
          position,
        }
      );
      commit("CHANGE_LEAD_BOARD_COLUMN_TITLE", { title: newTitle, columnId });
    } catch (e) {
      console.error(e);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
