import Vue from "vue";
import lodash from "lodash";

const state = {
  totalRows: 0,
  jobPositions: [],
  permissions: [],
  users: [],
};

const mutations = {
  SET_USERS(state, users) {
    Vue.set(state, "users", users);
  },
  UPDATE_USERS(state, userData) {
    const index = state.users.findIndex(({ id }) => userData.id === id);
    state.users.splice(index, 1, userData);
  },
  SET_TOTAL_ROWS(state, totalRows) {
    state.totalRows = totalRows;
  },
  SET_PERMISSIONS_DATA(state, permissions) {
    state.permissions = permissions;
  },
  SET_JOB_POSITIONS_DATA(state, positions) {
    state.jobPositions = lodash.cloneDeep(
      positions.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      })
    );
  },
};

const actions = {
  resetUsersData({ commit }) {
    commit("SET_USERS", []);
    commit("SET_PERMISSIONS_DATA", []);
    commit("SET_TOTAL_ROWS", 0);
    commit("SET_JOB_POSITIONS_DATA", []);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
