<template>
  <b-sidebar
    id="createLeadItemSidebar"
    ref="createLeadItemSidebar"
    right
    backdrop
    sidebar-class="fu-sidebar fu-sidebar__user"
    header-class="fu-sidebar__header"
    body-class="fu-sidebar__body flex-wrap align-items-start"
    footer-class="fu-sidebar__footer"
    @hidden="closeSideBar"
  >
    <template #header>
      <span>Create {{ isLeadBoard ? "Lead board" : "Lead forward" }}</span>
      <i class="far fa-times fa-1x" @click="closeSideBar"></i>
    </template>

    <validation-observer
      ref="createLeadItemForm"
      class="d-flex flex-wrap col-12 mt-3 px-0"
    >
      <validation-provider
        tag="label"
        name="title"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0"
      >
        <span class="w-100 font-weight-bold">Title *</span><br />
        <input
          id="kt-input-lead-title"
          v-model="title"
          type="text"
          name="title"
          required
          placeholder="Title"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="div"
        name="company"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0 mt-3"
      >
        <label class="col-6 px-0 font-weight-bold">Company *</label>
        <multiselect
          v-model="companyId"
          :options="companies"
          :loading="isLoading"
          label="name"
          track-by="name"
          placeholder="Country"
          class="multiselect"
          :class="{ multiselect__disabled: isLoading }"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder"> Company </template>

          <template slot="option" slot-scope="props">
            <span class="kt-select-item">{{ props.option.name }}</span>
          </template>

          <template slot="noResult">
            <span>No country found: "{{ searchValue }}" </span>
          </template>
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>

      <!-- START OF LEAD API SWITCH -->
      <div class="d-flex align-items-center col-12 px-0 mt-4 mb-3">
        <span class="col-3 p-0 font-weight-bold"
          >{{ isLeadBoard ? "Lead API" : "Enable lead" }}:
        </span>
        <b-form-checkbox
          v-if="isLeadBoard"
          id="kt-checkbox-lead-api-enable"
          v-model="apiEnabled"
          name="is-archived"
          size="lg"
          switch
          class="ml-4 mb-3 fu-checkbox"
          :disabled="isLoading"
          @change="setLeadBoardApi"
        >
        </b-form-checkbox>

        <b-form-checkbox
          v-else
          id="kt-checkbox-lead-active-forward"
          v-model="isActive"
          name="is-archived"
          size="lg"
          switch
          class="ml-4 mb-3 fu-checkbox"
          :disabled="isLoading"
        >
        </b-form-checkbox>
        <span v-if="isLeadBoard" class="mb-0 ml-5">{{
          apiEnabled ? "Active" : "Disabled"
        }}</span>
        <span v-else class="mb-0 ml-5">{{
          isActive ? "Active" : "Disabled"
        }}</span>
      </div>
      <!-- END OF LEAD API SWITCH -->

      <validation-provider
        v-if="!isLeadBoard || (isLeadBoard && apiEnabled)"
        tag="div"
        name="page"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0 mt-3"
      >
        <label class="col-6 px-0 font-weight-bold">Page *</label>
        <multiselect
          v-model="facebookPageId"
          :options="facebookPagesArray"
          :loading="isLoading"
          label="name"
          track-by="name"
          placeholder="Facebook pages"
          class="multiselect"
          :class="{
            multiselect__disabled: disabledMultiselect(false),
          }"
          :disabled="disabledMultiselect(false)"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder"> Facebook pages </template>

          <template slot="option" slot-scope="props">
            <span class="kt-select-item">{{ props.option.name }}</span>
          </template>

          <template slot="noResult">
            <span>No country found: "{{ searchValue }}" </span>
          </template>
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        v-if="!isLeadBoard || (isLeadBoard && apiEnabled)"
        tag="div"
        name="lead form"
        rules="required"
        v-slot="{ errors }"
        class="col-12 px-0 mt-3"
      >
        <label class="col-6 px-0 font-weight-bold">Lead form *</label>
        <multiselect
          v-model="facebookFormId"
          :options="leadFormArray"
          :loading="isLoading"
          label="name"
          track-by="name"
          placeholder="Facebook pages"
          class="multiselect"
          :class="{
            multiselect__disabled: disabledMultiselect(true),
          }"
          :disabled="disabledMultiselect(true)"
          @search-change="searchValue = $event"
        >
          <template slot="placeholder"> Lead forms </template>

          <template slot="option" slot-scope="props">
            <span class="kt-select-item">{{ props.option.name }}</span>
          </template>

          <template slot="noResult">
            <span>No country found: "{{ searchValue }}" </span>
          </template>
        </multiselect>
        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>

      <validation-provider
        tag="label"
        name="description"
        v-slot="{ errors }"
        class="col-12 px-0 mt-3"
      >
        <span class="w-100 font-weight-bold">Description</span><br />
        <input
          id="kt-input-lead-description"
          v-model="description"
          type="text"
          name="description"
          placeholder="Description"
          class="fu-login__input"
          :disabled="isLoading"
        />
        <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
      </validation-provider>

      <div class="d-flex align-items-center col-12 px-0 mt-3 mb-4">
        <span class="col-3 p-0 font-weight-bold">Archived: </span>
        <b-form-checkbox
          id="kt-checkbox-lead-archived"
          v-model="isDisabled"
          name="is-archived"
          size="lg"
          switch
          class="ml-4 mb-3 fu-checkbox"
          :disabled="isLoading"
        >
        </b-form-checkbox>
        <span class="mb-0 ml-5">{{ isDisabled ? "Active" : "Disabled" }}</span>
      </div>

      <div
        v-if="isLeadBoard"
        class="d-flex flex-wrap col-12 px-0 fu-border__lightgrey-top"
      >
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100 mt-4"
        >
          <h5>Members:</h5>
          <i
            v-if="leadBoardMembers.length > 0"
            v-b-tooltip.hover
            data-toggle="tooltip"
            data-placement="top"
            title="Apart from members and clients, admins can also edit lead boards"
            class="far fa-info-circle ml-3 fu-search-bar__status-tooltip"
          ></i>
        </div>

        <div
          v-for="(item, index) in leadBoardMembers"
          :key="index"
          class="d-flex flex-wrap col-12 px-0 mt-3"
        >
          <validation-provider
            tag="div"
            :name="`Member ${index + 1}: User`"
            rules="required"
            v-slot="{ errors }"
            class="col-lg-6 col-12 px-0 pr-lg-3 pr-0 mt-2"
          >
            <span class="w-100 font-weight-bold"
              >Member {{ index + 1 }}: User *</span
            >
            <br />
            <multiselect
              v-model="item.userId"
              :options="users"
              :loading="isLoading"
              :custom-label="memberFullName"
              track-by="id"
              placeholder="User"
              class="multiselect kt-div-input"
              :class="{ multiselect__disabled: isLoading }"
              :disabled="isLoading"
              @search-change="searchValue = $event"
            >
              <template slot="placeholder"> User </template>

              <template slot="option" slot-scope="props">
                <span class="kt-select-item">{{
                  `${props.option.firstName} ${props.option.lastName}`
                }}</span>
              </template>

              <template slot="noResult">
                <span>No position found: "{{ searchValue }}" </span>
              </template>
            </multiselect>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>

          <validation-provider
            tag="label"
            :name="`Member ${index + 1}: Type`"
            rules="required"
            class="col-lg-5 col-12 px-0 pl-0 mt-2"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Member {{ index + 1 }}: Type *</span
            >
            <br />
            <select
              v-model="item.roleId"
              class="fu-login__input kt-select-lead-member-type mt-0 pt-3"
              :class="{ 'fu-select__disabled': isLoading }"
              :disabled="isLoading"
            >
              <option
                v-for="option in userRoles"
                :key="option.id"
                :value="option.id"
                class="kt-select-lead-member-type-option"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <button
            class="col-lg-1 col-1 px-0 mb-lg-0 mb-3 mt-lg-4 mt-2 multiselect__option-delete fu-large kt-button-remove-lead-forward-contact"
            @click="removeLeadMember(index)"
            :disabled="isLoading"
          >
            <span class="d-flex align-items-center"
              ><i class="far fa-trash-alt ml-lg-3 ml-0"></i
              ><span class="d-lg-none d-block ml-2">Delete</span></span
            >
          </button>
        </div>
        <div class="d-flex justify-content-end col-12 px-0 my-3">
          <button
            id="kt-button-add-lead-board-member"
            class="fu-contact__button"
            @click="addNewLeadMember"
            :disabled="isLoading"
          >
            <span><i class="far fa-plus mr-2"></i>Add new</span>
          </button>
        </div>
      </div>

      <div v-else class="d-flex flex-wrap col-12 px-0 fu-border__lightgrey-top">
        <h5 class="mt-4">Forward leads to:</h5>

        <div
          v-for="(_, index) in forwardLeadContacts"
          :key="index"
          class="d-flex flex-wrap col-12 px-0 mt-3"
        >
          <validation-provider
            tag="label"
            :name="`Contact ${index + 1}: Email`"
            rules="required|email"
            class="col-11 px-0 pr-3 mt-2"
            v-slot="{ errors }"
          >
            <span class="w-100 font-weight-bold"
              >Contact {{ index + 1 }}: Email *</span
            >
            <br />
            <input
              v-model="forwardLeadContacts[index]"
              type="email"
              name="email"
              placeholder="Email"
              class="fu-login__input kt-input-lead-forward-contact-email"
              :disabled="isLoading"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <button
            class="col-1 px-0 mb-3 mt-5 multiselect__option-delete fu-large kt-button-remove-lead-forward-contact"
            @click="removeForwardingContact(index)"
            :disabled="isLoading || forwardLeadContacts.length === 1"
          >
            <span><i class="far fa-trash-alt mr-2"></i></span>
          </button>
        </div>
        <div class="d-flex justify-content-end col-12 px-0 my-3">
          <button
            id="kt-button-add-lead-forward-contact"
            class="fu-contact__button"
            @click="addNewForwardContact"
            :disabled="isLoading"
          >
            <span><i class="far fa-plus mr-2"></i>Add new</span>
          </button>
        </div>
      </div>
    </validation-observer>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center col-12">
        <button
          id="kt-button-lead-submit-create"
          class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
          @click="createLeadItem"
          :disabled="isLoading"
        >
          <span><i class="far fa-plus mr-2"></i>Create</span>
        </button>
        <button
          id="kt-button-lead-submit-close"
          class="btn btn-primary p-3"
          @click="closeSideBar"
          :disabled="isLoading"
        >
          <span><i class="far fa-times mr-2"></i>Cancel</span>
        </button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  name: "CreateLeadItemSidebar",
  props: {
    isLeadBoard: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      searchValue: "",
      facebookPagesArray: [],
      leadFormArray: [],
      forwardLeadContacts: [""],
      leadBoardMembers: [],
    };
  },
  computed: {
    getGetterPath() {
      return this.isLeadBoard
        ? "leadBoard/getLeadBoardFieldData"
        : "leadForwarding/getLeadForwardingFieldData";
    },
    getMutationPath() {
      return this.isLeadBoard
        ? "leadBoard/CHANGE_LEAD_BOARD_FIELD"
        : "leadForwarding/CHANGE_LEAD_FORWARDING_FIELD";
    },
    currentBoard() {
      return this.$store.state.leadTable.currentBoard;
    },
    companies() {
      return this.$store.state.leadBoards.leadMeta.companies;
    },
    isSidebarShown() {
      return this.$store.state.leadBoards.currentShownSidebar === "create";
    },
    users() {
      return this.$store.state.leadBoards.leadMeta.users;
    },
    userRoles() {
      return this.$store.state.leadBoards.leadMeta.roles;
    },
    moduleName() {
      return this.isLeadBoard ? "leadBoard" : "leadForwarding";
    },
    title: {
      get() {
        return this.$store.getters[this.getGetterPath]("title");
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "title",
          fieldValue,
        });
      },
    },
    companyId: {
      get() {
        const selectedId = this.$store.getters[this.getGetterPath]("companyId");
        return this.companies.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "companyId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    facebookPageId: {
      get() {
        const selectedId = this.$store.getters[this.getGetterPath](
          "facebookPageId"
        );
        return this.facebookPagesArray.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "facebookPageId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
    description: {
      get() {
        return this.$store.getters[this.getGetterPath]("description");
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "description",
          fieldValue,
        });
      },
    },
    apiEnabled: {
      get() {
        return this.$store.getters[this.getGetterPath]("apiEnabled");
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "apiEnabled",
          fieldValue,
        });
      },
    },
    isActive: {
      get() {
        return this.$store.getters[this.getGetterPath]("isActive");
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "isActive",
          fieldValue,
        });
      },
    },
    isDisabled: {
      get() {
        return this.$store.getters[this.getGetterPath]("isDisabled");
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "isDisabled",
          fieldValue,
        });
      },
    },
    facebookFormId: {
      get() {
        const selectedId = this.$store.getters[this.getGetterPath](
          "facebookFormId"
        );
        return this.leadFormArray.find(({ id }) => id === selectedId);
      },
      set(fieldValue) {
        this.$store.commit(this.getMutationPath, {
          fieldName: "facebookFormId",
          fieldValue: fieldValue ? fieldValue.id : null,
        });
      },
    },
  },
  watch: {
    forwardLeadContacts: {
      deep: true,
      handler(value) {
        this.$store.commit(
          "leadForwarding/UPDATE_LEAD_FORWARDING_CONTACTS",
          value
        );
      },
    },
    leadBoardMembers: {
      deep: true,
      handler(value) {
        const selectedContacts = this.$lodash
          .cloneDeep(value)
          .map((contact) => {
            contact.userId
              ? (contact.userId = contact.userId.id)
              : (contact.userId = null);
            return contact;
          });
        this.$store.commit(
          "leadBoard/UPDATE_LEAD_BOARD_MEMBERS",
          selectedContacts
        );
      },
    },
    companyId(company) {
      if (company && this.isSidebarShown) {
        this.facebookPageId = null;
        this.facebookFormId = null;
        this.facebookPagesArray = this.$lodash.cloneDeep([]);
        this.leadFormArray = this.$lodash.cloneDeep([]);
        this.$store.commit(`${this.moduleName}/SET_FACEBOOK_FORM_TITLE`, null);
        this.getFacebookPages();
      } else if (this.isSidebarShown) {
        this.facebookPagesArray = this.$lodash.cloneDeep([]);
        this.leadFormArray = this.$lodash.cloneDeep([]);
      }
    },
    facebookPageId(page) {
      if (page && this.isSidebarShown) {
        this.facebookFormId = null;
        this.$store.commit(`${this.moduleName}/SET_FACEBOOK_FORM_TITLE`, null);
        this.getLeadForms();
      }
    },
    facebookFormId(selectedId) {
      if (selectedId !== null && this.isSidebarShown) {
        const selectedForm = this.leadFormArray.find(
          ({ id }) => id === selectedId.id
        );
        this.$store.commit(
          `${
            this.isLeadBoard ? "leadBoard" : "leadForwarding"
          }/SET_FACEBOOK_FORM_TITLE`,
          selectedForm ? selectedForm.name : ""
        );
      }
    },
  },
  methods: {
    memberFullName({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    disabledMultiselect(isLeadForm) {
      const idVariable = isLeadForm ? this.facebookPageId : this.companyId;
      return (
        this.isLoading || !idVariable || (this.isLeadBoard && !this.apiEnabled)
      );
    },
    setLeadBoardApi() {
      if (this.apiEnabled && this.companyId && this.isSidebarShown) {
        this.getFacebookPages();
      } else if (this.companyId) {
        this.facebookPageId = null;
        this.facebookFormId = null;
        this.$store.commit("leadBoard/SET_FACEBOOK_FORM_TITLE", null);
        this.facebookPagesArray = this.$lodash.cloneDeep([]);
        this.leadFormArray = this.$lodash.cloneDeep([]);
      }
    },
    removeForwardingContact(index) {
      if (this.forwardLeadContacts.length > 1) {
        const conf = confirm("Do you want to delete this contact?");
        if (conf) this.forwardLeadContacts.splice(index, 1);
      }
    },
    addNewForwardContact() {
      this.forwardLeadContacts.push("");
    },
    removeLeadMember(index) {
      const conf = confirm("Do you want to delete this member?");
      if (conf) this.leadBoardMembers.splice(index, 1);
    },
    addNewLeadMember() {
      this.leadBoardMembers.push({
        userId: null,
        roleId: null,
      });
    },

    closeSideBar() {
      this.$refs.createLeadItemSidebar.hide();
      this.isLeadBoard
        ? this.$store.commit("leadBoard/RESET_LEAD_BOARD_DATA")
        : this.$store.commit("leadForwarding/RESET_LEAD_FORWARDING_DATA");
      this.facebookPagesArray = this.$lodash.cloneDeep([]);
      this.leadFormArray = this.$lodash.cloneDeep([]);
      this.forwardLeadContacts = this.$lodash.cloneDeep([""]);
      this.leadBoardMembers = this.$lodash.cloneDeep([]);
      this.$refs.createLeadItemForm.reset();
    },
    errorToast(message) {
      this.$bvToast.toast(message, {
        title: "Create lead item failed",
        variant: "danger",
        solid: true,
      });
    },
    async getFacebookPages() {
      try {
        if (!this.isLeadBoard || this.apiEnabled) {
          this.isLoading = true;
          const companyId = this.companyId ? this.companyId.id : "null";
          let resPages = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}/companies/${companyId}/pages`
          );
          this.facebookPagesArray = this.$lodash.cloneDeep(resPages.data);
        }
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("Please check company credentials", {
          title: "Unable to get data from facebook",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getLeadForms() {
      try {
        if (!this.isLeadBoard || this.apiEnabled) {
          this.isLoading = true;
          const companyId = this.companyId ? this.companyId.id : null;
          const facebookPageId = this.facebookPageId
            ? this.facebookPageId.id
            : null;
          const facebookPage = this.facebookPagesArray.find(
            ({ id }) => id === facebookPageId
          );
          let resForms = await this.$axios.get(
            `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}/companies/${companyId}/pages/${facebookPageId}/forms?token=${facebookPage.accessToken}`
          );
          this.leadFormArray = this.$lodash.cloneDeep(resForms.data);
        }
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("Please check company credentials", {
          title: "Unable to get data from facebook",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    createLeadItem() {
      this.$refs.createLeadItemForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            const leadItemData = this.isLeadBoard
              ? this.$store.state.leadBoard.leadBoardData
              : this.$store.state.leadForwarding.leadForwardingData;

            await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}`,
              leadItemData
            );
            this.$emit("refreshLeadItems");
            this.$bvToast.toast("Success", {
              title: `Lead item was successfully created`,
              variant: "success",
              solid: true,
            });
            this.closeSideBar();
          } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
              let err = error.response.data.errors;
              for (const key in err) {
                this.errorToast(err[key][0]);
              }
            } else {
              this.errorToast(error.response.data.message);
            }
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
