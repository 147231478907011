import lodash from "lodash";

const state = {
  unassignedDeals: [],
  companiesWithoutLeadBoards: [],
  leadForwardsData: {
    leadForwards: null,
    companies: null,
  },
  dealsByCreator: [],
  dealsByLeadBoard: [],
  submittedQuestionnairesByUser: [],
};
const mutations = {
  SET_UNASSIGNED_DEALS(state, items) {
    state.unassignedDeals = lodash.cloneDeep(items);
  },
  SET_COMPANIES_WITHOUT_LEAD_BOARDS(state, items) {
    state.companiesWithoutLeadBoards = lodash.cloneDeep(items);
  },
  SET_LEAD_FORWARDS_DATA(state, items) {
    state.leadForwardsData = lodash.cloneDeep(items);
  },
  SET_DEALS_BY_CREATOR(state, items) {
    state.dealsByCreator = lodash.cloneDeep(items);
  },
  SET_DEALS_BY_LEAD_BOARD(state, items) {
    state.dealsByLeadBoard = lodash.cloneDeep(items);
  },
  SET_SUBMITTED_QUESTIONNAIRE_BY_USER(state, items) {
    state.submittedQuestionnairesByUser = lodash.cloneDeep(items);
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
