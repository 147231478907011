var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.formClass},[_c('validation-provider',{staticClass:"col-12 px-0 mb-0",attrs:{"tag":"div","name":"contact person","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.iframe)?_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Kontaktna oseba *")]):_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Contact person *")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactPerson),expression:"contactPerson"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"contact person","required":"","placeholder":"Contact person","disabled":_vm.isLoading},domProps:{"value":(_vm.contactPerson)},on:{"input":function($event){if($event.target.composing){ return; }_vm.contactPerson=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-lg-6 col-md-12 px-0 pr-lg-3 pr-0 mt-3",attrs:{"tag":"div","rules":{ email: true, max: 70, required: !_vm.phone }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.iframe)?_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("E-pošta *")]):_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Email *")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"fu-login__input",attrs:{"type":"email","name":"email","placeholder":"Email","disabled":_vm.isLoading},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"col-lg-6 col-md-12 px-0 pl-lg-3 pl-0 mt-3",attrs:{"tag":"div","name":"phone","rules":{ max: 30, regex: /^[+]?[0-9]*$/, required: !_vm.email }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.iframe)?_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Telefonska številka *")]):_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Phone number *")]),_vm._v(" "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"phone","placeholder":"Phone number","disabled":_vm.isLoading},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}])}),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-12 px-0 mt-3",attrs:{"tag":"div","name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Organization *")]),_c('br'),_c('multiselect',{staticClass:"multiselect mt-2",class:{ multiselect__disabled: _vm.isLoading },attrs:{"options":_vm.organization,"loading":_vm.isLoading,"label":"name","track-by":"id","placeholder":"Organization","disabled":_vm.isLoading},on:{"search-change":function($event){_vm.searchValue = $event}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"d-flex flex-wrap align-items-center justify-content-between"},[_c('span',[_vm._v(_vm._s(props.option.name))]),_c('button',{staticClass:"multiselect__option-delete",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOrganization(props.option)}}},[_c('i',{staticClass:"far fa-trash-alt mr-2"})])])]}}],null,true),model:{value:(_vm.clientId),callback:function ($$v) {_vm.clientId=$$v},expression:"clientId"}},[_c('template',{slot:"placeholder"},[_vm._v(" Organization")]),_c('template',{slot:"noResult"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[_c('span',[_vm._v("No position found: \""+_vm._s(_vm.searchValue)+"\" ")]),_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.addNewOrganization()}}},[_c('span',[_vm._v("Add new"),_c('i',{staticClass:"fal fa-plus text-white ml-3"})])])])])],2),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,272799097)}):_vm._e(),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-12 px-0 mt-3",attrs:{"tag":"div","name":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Title")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"title","placeholder":"Title","disabled":_vm.isLoading},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1325382812)}):_vm._e(),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-6 px-0 pr-3 mt-3",attrs:{"tag":"div","name":"value","rules":{
      regex: /^[0-9]{0,9}(\.[0-9]{1,2})?$/,
      required: !!_vm.currencyId,
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Value")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"fu-login__input",attrs:{"type":"text","name":"value","placeholder":"Value","disabled":_vm.isLoading},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value}}}),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2308100439)}):_vm._e(),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-6 px-0 pl-3 mt-3",attrs:{"tag":"div","name":"currency","rules":_vm.value ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Currency")]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currencyId),expression:"currencyId"}],staticClass:"fu-login__input fu-select",class:{ 'fu-select__disabled': _vm.isLoading },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currencyId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.currencies),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3878021994)}):_vm._e(),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-6 px-0 pr-3 mt-3",attrs:{"tag":"div","name":"column","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Column")]),_c('br'),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.columnId),expression:"columnId"}],staticClass:"fu-login__input fu-select",class:{ 'fu-select__disabled': _vm.isLoading },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.columnId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.columns),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0),_c('span',{staticClass:"text-danger pt-0 pb-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3531271086)}):_vm._e(),(!_vm.iframe)?_c('validation-provider',{staticClass:"col-6 px-0 pl-3 mt-3",attrs:{"tag":"div","name":"deal owner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('span',{staticClass:"w-100 font-weight-bold"},[_vm._v("Deal owner")]),_c('br'),_c('multiselect',{staticClass:"multiselect mt-2",attrs:{"options":_vm.users,"custom-label":_vm.memberFullName,"track-by":"id","placeholder":"Deal owner"},on:{"search-change":function($event){_vm.searchValue = $event}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('span',{staticClass:"kt-select-item"},[_vm._v(_vm._s(((props.option.firstName) + " " + (props.option.lastName))))])]}}],null,true),model:{value:(_vm.dealOwner),callback:function ($$v) {_vm.dealOwner=$$v},expression:"dealOwner"}},[_c('template',{slot:"placeholder"},[_vm._v(" Deal owner")]),_c('template',{slot:"noResult"},[_c('span',[_vm._v("No position found: \""+_vm._s(_vm.searchValue)+"\" ")])])],2),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1751805919)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }