<template>
  <b-modal
    id="emailQuestionnaireModal"
    ref="emailQuestionnaireModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <template #modal-header>
      <div><h3>Enter email address</h3></div>
    </template>
    <validation-observer ref="sendQuestionnaireEmail" class="mt-3">
      <validation-provider
        v-for="(email, index) in emails"
        :key="index"
        tag="div"
        name="emails"
        v-slot="{ errors }"
        rules="required|email"
        class="col-12 px-0 pr-lg-3 pr-0"
      >
        <div class="d-flex align-items-center">
          <b-input
            class="m-2 w-100 fu-login__input"
            v-model="emails[index]"
            placeholder="Email.."
          />
          <i
            @click="removeEmail(index)"
            class="fas fa-trash fu-contact__button"
          ></i>
        </div>

        <span class="text-danger">{{ errors[0] }}</span>
      </validation-provider>
      <div>
        <button
          class="d-flex justify-content-center w-100 mt-2 fu-contact__button"
          @click="addEmailAddress"
          :disabled="isLoading"
        >
          <span><i class="ml-3 mr-2 fas fa-plus"></i>Add address</span>
        </button>
      </div>
    </validation-observer>

    <template #modal-footer>
      <fu-button
        ref="emailQuestionnaireButton"
        title="Send"
        id="emailQuestionnaireButton"
        :is-loading="isLoading"
        :disabled="isLoading"
        @click="sendQuestionnaire"
      >
        <template #button-icon
          ><i class="fas fa-paper-plane mr-2"></i
        ></template>
      </fu-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "LeadBoardEmailQuestionnaireModal",
  data() {
    return {
      isLoading: false,
      emails: [null],
    };
  },
  computed: {
    companyContactEmails() {
      return this.$store.state.columnDeal.dealMeta.companyContactEmails;
    },
  },
  methods: {
    async sendQuestionnaire() {
      this.$refs.sendQuestionnaireEmail.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.$route.params.dealId}/send-questionnaire`,
              {
                emails: this.emails,
              }
            );
            this.$bvToast.toast("Questionnaire was successfully sent", {
              title: `Success`,
              variant: "success",
              solid: true,
            });
            this.closeModal();
            this.emails = [null];
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Sending questionnaire emails failed", {
              title: `Failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
    addEmailAddress() {
      this.emails.push(null);
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    closeModal() {
      this.$refs.emailQuestionnaireModal.hide();
    },
  },
  watch: {
    companyContactEmails() {
      this.emails = this.companyContactEmails;
    },
  },
};
</script>
