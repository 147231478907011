import lodash from "lodash";

const state = {
  leadForwardingData: {
    title: null,
    companyId: null,
    description: null,
    isActive: false,
    isDisabled: false,
    facebookFormId: null,
    facebookPageId: null,
    facebookFormTitle: null,
    emails: [""],
  },
};

const mutations = {
  SET_LEAD_FORWARDING_DATA(state, item) {
    state.leadForwardingData = lodash.cloneDeep(item);
  },
  UPDATE_LEAD_FORWARDING_CONTACTS(state, contacts) {
    state.leadForwardingData.emails = lodash.cloneDeep(contacts);
  },
  CHANGE_LEAD_FORWARDING_FIELD(state, { fieldName, fieldValue }) {
    state.leadForwardingData[fieldName] = fieldValue;
  },
  SET_FACEBOOK_FORM_TITLE(state, title) {
    state.leadForwardingData.facebookFormTitle = title;
  },
  RESET_LEAD_FORWARDING_DATA(state) {
    state.leadForwardingData = lodash.cloneDeep({
      title: null,
      companyId: null,
      description: null,
      isActive: false,
      isDisabled: false,
      facebookFormId: null,
      facebookPageId: null,
      facebookFormTitle: null,
      emails: [""],
    });
  },
};

const actions = {};

const getters = {
  getLeadForwardingFieldData: (state) => (fieldName) => {
    return state.leadForwardingData[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
