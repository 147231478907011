<template>
  <div class="p-2">
    <h5>By User</h5>

    <ol type="1">
      <li v-for="user in submittedQuestionnairesByUser" :key="user.id">
        {{ user.firstName + " " + user.lastName }} ({{
          user.submittedQuestionnairesCount
        }})
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "SubmittedQuestionnairesByUser",
  computed: {
    submittedQuestionnairesByUser() {
      return this.$store.state.analytics.submittedQuestionnairesByUser;
    },
  },
};
</script>

<style scoped></style>
