<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-5">
    <ul class="nav nav-tabs w-100 fu-table__routing">
      <auth-action permission-name="lead_board.read,lead_board.member">
        <li class="nav-item">
          <button
            id="kt-button-table-lead-boards"
            class="nav-link left"
            :class="{ active: currentBoard === 'lead-boards' }"
            :disabled="currentBoard === 'lead-boards'"
            @click="changeTableContent('lead-boards')"
          >
            Lead boards
          </button>
        </li>
      </auth-action>
      <auth-action permission-name="lead_forward.read">
        <li class="nav-item">
          <button
            id="kt-button-table-lead-forward"
            class="nav-link right"
            :class="{ active: currentBoard === 'lead-forwards' }"
            :disabled="currentBoard === 'lead-forwards'"
            @click="changeTableContent('lead-forwards')"
          >
            Lead forwarding
          </button>
        </li>
      </auth-action>
    </ul>
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="48vh"
      :sort-compare-options="{ numeric: true, ignorePunctuation: true }"
      :items="tableItems"
      :fields="selectedTableFields"
      :per-page="itemsPerPage"
    >
      <template #head(select)>
        <b-form-checkbox
          :checked="allSelectedOnPage"
          @change="selectAll"
        ></b-form-checkbox>
      </template>

      <template #cell(select)="{ item }">
        <b-form-checkbox
          :checked="selectedItemsId.includes(item.id)"
          @change="selectItem(item.id)"
        ></b-form-checkbox>
      </template>

      <template #cell(actions)="{ item }">
        <div
          class="d-flex flex-wrap align-items-center justify-content-center w-100"
        >
          <auth-action
            v-if="isLeadBoard"
            permission-name="lead_board.read,lead_board.member"
          >
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="goToLeadBoard(item.id)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Go to lead board"
                class="far fa-eye fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action v-if="!isLeadBoard" permission-name="lead_forward.read">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editLeadItem(item.id, true)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="View"
                class="far fa-eye fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action :permission-name="`${permissionPrefix}.write`">
            <button
              class="fu-button__transparent px-0 mr-3"
              @click="editLeadItem(item.id, false)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
                class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>

          <auth-action :permission-name="`${permissionPrefix}.delete`">
            <button
              class="fu-button__transparent px-0"
              @click="deleteLeadItem(item)"
            >
              <i
                v-b-tooltip.hover
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
                class="far fa-trash-alt fu-search-bar__status-tooltip fu-table__tooltip"
              ></i>
            </button>
          </auth-action>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "LeadsTable",
  props: {
    isLeadBoard: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      allSelectedOnPage: false,
      selectedItemsId: [],
    };
  },
  computed: {
    tableItems() {
      return this.$store.state.leadBoards.leadItems;
    },
    currentBoard() {
      return this.$store.state.leadTable.currentBoard;
    },
    itemsPerPage() {
      return this.$store.state.perPage;
    },
    selectedTableFields() {
      return this.currentBoard === "lead-boards"
        ? this.$store.state.leadTable.leadBoardTableFields
        : this.$store.state.leadTable.leadForwardingTableFields;
    },
    permissionPrefix() {
      return this.isLeadBoard ? "lead_board" : "lead_forward";
    },
  },
  watch: {
    tableItems() {
      this.checkIfAllSelected();
    },
    selectedItemsId(value) {
      this.$emit("selectedItems", value);
      this.checkIfAllSelected();
    },
  },
  methods: {
    changeTableContent(value) {
      if (value !== this.currentBoard) {
        this.$store.commit("leadBoards/SET_LEAD_ITEMS", []);
        this.$store.commit("leadTable/SET_CURRENT_BOARD", value);
        this.allSelectedOnPage = false;
        this.selectedItemsId = this.$lodash.cloneDeep([]);
        this.$emit("changeTableContent");
      }
    },
    checkIfAllSelected() {
      const itemsIds = this.tableItems.map(({ id }) => id);
      let count = 0;
      itemsIds.forEach((itemId) => {
        if (this.selectedItemsId.includes(itemId)) count++;
      });

      this.allSelectedOnPage =
        itemsIds.length === count && itemsIds.length !== 0;
    },
    selectItem(itemId) {
      if (!this.selectedItemsId.includes(itemId)) {
        this.selectedItemsId.push(itemId);
      } else {
        const index = this.selectedItemsId.indexOf(itemId);
        if (index > -1) this.selectedItemsId.splice(index, 1);
      }
    },
    selectAll(value) {
      this.tableItems.forEach((item) => {
        if (value) {
          if (!this.selectedItemsId.includes(item.id)) {
            this.selectedItemsId.push(item.id);
          }
        } else {
          const index = this.selectedItemsId.indexOf(item.id);
          if (index > -1) this.selectedItemsId.splice(index, 1);
        }
      });
    },
    goToLeadBoard(id) {
      if (this.isLeadBoard) {
        this.$router.push({ name: "LeadBoard", params: { id: id } });
      }
    },
    editLeadItem(selectedId, onlyRead) {
      const leadItem = this.tableItems.find(({ id }) => id === selectedId);
      this.$store.commit("leadBoards/SET_SHOWN_SIDEBAR", "edit");
      this.currentBoard === "lead-boards"
        ? this.$store.commit("leadBoard/SET_LEAD_BOARD_DATA", leadItem)
        : this.$store.commit(
            "leadForwarding/SET_LEAD_FORWARDING_DATA",
            leadItem
          );
      this.$emit("onlyReadLead", onlyRead);
      this.$root.$emit("bv::toggle::collapse", "editLeadItemSidebar");
    },
    deleteLeadItem(selectedItem) {
      this.$emit("deleteItem", selectedItem);
    },
  },
};
</script>
