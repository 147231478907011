import Vue from "vue";
import lodash from "lodash";

const state = {
  questionnaireData: { questions: [] },
  dealOwners: [],
  deal: {
    contactPerson: null,
    phone: null,
    email: null,
    clientId: null,
    title: null,
    value: null,
    currencyId: null,
    columnId: null,
    ownerId: null,
    meetingDateTime: null,
    activeQuestionnaireId: null,
    activeQuestionnaire: {},
    customFields: [],
    todoItems: [],
    comments: [],
    questionnaires: [],
  },
  dealMeta: {
    clients: [],
    currencies: [],
    columns: [],
    campaigns: [],
    emailRuleTypes: [],
    questionTypes: [],
    users: [],
    companyContactEmails: [],
  },
};

const mutations = {
  SET_DEAL_DATA(state, dealData) {
    state.deal = lodash.cloneDeep(dealData);
  },
  SET_QUESTIONNAIRE_DATA(state, data) {
    Vue.set(state, "questionnaireData", data);
  },
  CHANGE_QUESTIONNAIRE_BOOLEAN(state, { questionIndex, answerIndex }) {
    state.questionnaireData.questions[questionIndex].answers[
      answerIndex
    ].isSubmitted = true;
    state.questionnaireData.questions[questionIndex].answers[
      answerIndex === 0 ? 1 : 0
    ].isSubmitted = false;
  },
  CHANGE_DEAL_FIELD(state, { fieldName, fieldValue }) {
    state.deal[fieldName] = fieldValue;
  },
  UPDATE_ACTIVE_QUESTIONNAIRE(state, data) {
    state.deal.activeQuestionnaire = lodash.cloneDeep(data);
  },
  ADD_TODO_ITEM(state, item) {
    state.deal.todoItems.reverse().push(item);
    state.deal.todoItems.reverse();
  },
  ADD_DEAL_COMMENT(state, comment) {
    state.deal.comments.reverse().push(comment);
    state.deal.comments.reverse();
  },
  UPDATE_TODO_ITEM(state, { item, index }) {
    state.deal.todoItems.splice(index, 1, item);
  },
  DELETE_TODO_ITEM(state, index) {
    state.deal.todoItems.splice(index, 1);
  },
  SET_DEAL_META(state, meta) {
    state.dealMeta = lodash.cloneDeep(meta);
  },
  SET_DEAL_OWNERS(state, dealOwners) {
    Vue.set(state, "dealOwners", dealOwners.reverse());
  },
  SET_ORGANIZATION_DATA(state, organization) {
    state.dealMeta.clients = organization.sort((a, b) => {
      if (a > b) {
        return 1;
      }
      if (b > a) {
        return -1;
      }
      return 0;
    });
  },
  RESET_DEAL_DATA(state) {
    state.deal = {
      contactPerson: null,
      phone: null,
      email: null,
      clientId: null,
      title: null,
      value: null,
      currencyId: null,
      columnId: null,
      ownerId: null,
      meetingDateTime: null,
      activeQuestionnaireId: null,
      activeQuestionnaire: {},
      customFields: [],
      todoItems: [],
      comments: [],
      questionnaires: [],
    };
  },
};

const actions = {};

const getters = {
  getDealFieldData: (state) => (fieldName) => {
    return state.deal[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
