<template>
  <login-template title="login" subtitle="Please login to your account">
    <template #body>
      <validation-observer tag="div" ref="loginForm" class="w-100 mt-3">
        <validation-provider
          tag="div"
          name="Email"
          rules="required|email|max:45"
          v-slot="{ errors }"
        >
          <label class="w-100 font-weight-bold">
            <span><i class="fas fa-envelope fa-1x pr-2"></i>Email</span><br />
            <input
              v-model="email"
              type="email"
              name="email"
              maxlength="45"
              required
              placeholder="Email"
              :disabled="isLoading"
              class="fu-login__input"
              @keyup.enter="loginUser"
            />
          </label>
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="div"
          name="Password"
          class="mt-3"
          rules="required"
          v-slot="{ errors }"
        >
          <label class="w-100 font-weight-bold">
            <span><i class="fas fa-lock fa-1x pr-2"></i>Password</span><br />
            <input
              v-model="password"
              type="password"
              name="password"
              required
              placeholder="Password"
              :disabled="isLoading"
              class="fu-login__input"
              @keyup.enter="loginUser"
            />
          </label>
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </validation-observer>
    </template>

    <template #footer>
      <fu-button
        title="login"
        :id="'loginButton'"
        :isLoading="isLoading"
        @click="loginUser"
      />
      <router-link to="/forgot-password" class="mt-2 fu-login__link"
        >Forgot password</router-link
      >
    </template>
  </login-template>
</template>

<script>
import LoginTemplate from "@/components/login/LoginTemplate";

export default {
  name: "Login",
  components: { LoginTemplate },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
  },
  mounted() {
    if (
      this.user &&
      ((this.user.has2fa && this.user.secretSubmitted) || !this.user.has2fa)
    ) {
      this.$router.push({ name: "UserDashboard" });
    } else if (this.user && !this.user.secretSubmitted)
      this.$bvModal.show("twoFactorModal");
  },
  methods: {
    loginUser() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          try {
            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/auth/login`,
              {
                email: this.email,
                password: this.password,
              }
            );
            if (res.data.user.has2fa) {
              if (!res.data.user.hasSecret) {
                const hasSecretRes = await this.$axios.get(
                  `${process.env.VUE_APP_API_URL}/api/auth/generate-qr`
                );
                this.$store.commit("SET_QR_CODE", hasSecretRes.data);
              }
              this.$store.commit("SET_USER_DATA", res.data.user);
              this.$bvModal.show("twoFactorModal");
            } else {
              this.$store.commit("SET_USER_DATA", res.data.user);
              await this.$router.push({
                name: "UserDashboard",
              });
            }
          } catch (error) {
            console.error(error);
            this.$bvToast.toast(error.response.data.message, {
              title: "Login failed",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
