<template>
  <login-template :title="title" subtitle="Please set new password">
    <template #body>
      <validation-observer tag="div" ref="setPasswordForm" class="w-100 mt-3">
        <validation-provider tag="div" rules="required" v-slot="{ errors }">
          <label class="w-100 font-weight-bold">
            <span><i class="fas fa-lock fa-1x pr-2"></i>New password</span
            ><br />
            <input
              v-model="newPassword"
              type="password"
              name="New password"
              required
              placeholder="New password"
              :disabled="isLoading"
              class="fu-login__input"
              @keyup.enter="setPassword"
            />
          </label>
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="div"
          rules="required"
          class="mt-3"
          v-slot="{ errors }"
        >
          <label class="w-100 font-weight-bold">
            <span><i class="fas fa-lock fa-1x pr-2"></i>Confirm password</span
            ><br />
            <input
              v-model="confirmPassword"
              type="password"
              name="Confirm password"
              required
              placeholder="Confirm password"
              :disabled="isLoading"
              class="fu-login__input"
              :class="{ 'border-primary': showPasswordError }"
              @keyup.enter="setPassword"
            />
          </label>
          <span class="text-danger pt-0 pb-0">{{
            showPasswordError ? "Passwords do not match" : errors[0]
          }}</span>
        </validation-provider>
      </validation-observer>
    </template>

    <template #footer>
      <fu-button
        title="Confirm password"
        :id="currentEndpoint"
        :isLoading="isLoading"
        @click="setPassword"
      />
    </template>
  </login-template>
</template>

<script>
import LoginTemplate from "@/components/login/LoginTemplate";

export default {
  name: "SetPassword",
  components: { LoginTemplate },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      showPasswordError: false,
      isLoading: false,
    };
  },
  computed: {
    title() {
      return this.$route.path === "/reset-password"
        ? "reset password"
        : "first login";
    },
    currentEndpoint() {
      return this.$route.path.split("/")[1];
    },
    checkPasswords() {
      return this.newPassword === this.confirmPassword;
    },
    user() {
      return this.$store.state.loggedUser;
    },
  },
  mounted() {
    if (
      this.user &&
      !(
        this.$store.state.loggedUser.has2fa &&
        !this.$store.state.loggedUser.secretSubmitted
      )
    ) {
      this.$router.push({ name: "UserDashboard" });
    }
    if (!this.$route.query.token) this.$router.push({ name: "Login" });
  },
  methods: {
    setPassword() {
      this.$refs.setPasswordForm.validate().then(async (success) => {
        if (success) {
          try {
            if (this.checkPasswords) {
              this.isLoading = true;
              this.showPasswordError = false;
              await this.$axios.post(
                `${process.env.VUE_APP_API_URL}/api/auth/confirm-${this.currentEndpoint}`,
                {
                  token: this.$route.query.token,
                  password: this.newPassword,
                  password_confirmation: this.confirmPassword,
                }
              );
              this.$bvToast.toast("A new password is set", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              setTimeout(() => {
                this.isLoading = false;
                this.$router.push({ name: "Login" });
              }, 1000);
            } else {
              this.showPasswordError = true;
            }
          } catch (error) {
            console.error(error);
            this.$bvToast.toast(error.response.data.message, {
              title: "Reset password failed",
              variant: "danger",
              solid: true,
            });
            this.isLoading = false;
          }
        } else {
          this.showPasswordError = false;
        }
      });
    },
  },
};
</script>
