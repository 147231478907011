<template>
  <main class="d-flex flex-wrap mb-5 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <search-bar
        title="Create and view lead boards and lead forwarding"
        button-title="Create board"
        :selected-items="selectedItems"
        :is-loading="isLoading"
        multiple-delete-button-id="kt-button-delete-selected-company"
        :permissionName="`${permissionPrefix}.write`"
        :delete-permission-name="`${permissionPrefix}.delete`"
        @createNew="createNewBoard"
        @changeStatus="fetchLeadItems"
        @searchChanged="searchChanged($event)"
        @deleteSelectedItem="deleteSelectedItem($event)"
      >
      </search-bar>

      <lead-board-table
        ref="leadBoardTable"
        :is-lead-board="isLeadBoard"
        @selectedItems="selectItems($event)"
        @deleteItem="deleteSelectedItem($event)"
        @onlyReadLead="onlyReadLead = $event"
        @changeTableContent="fetchLeadData"
      ></lead-board-table>
    </b-overlay>

    <create-lead-item-sidebar
      :is-lead-board="isLeadBoard"
      @refreshLeadItems="fetchLeadItems"
    ></create-lead-item-sidebar>

    <edit-lead-item-sidebar
      :is-lead-board="isLeadBoard"
      :only-read-lead-item="onlyReadLead"
      @refreshLeadItems="fetchLeadItems"
    ></edit-lead-item-sidebar>
    <!-- START OF PAGINATION -->
    <div class="d-flex align-items-end flex-column col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="lg"
        class="mb-0 mt-auto p-3"
        col-12
        @input="fetchLeadItems"
      ></b-pagination>
    </div>
    <!-- END OF PAGINATION -->
  </main>
</template>

<script>
import SearchBar from "@/components/gadgets/SearchBar";
import LeadBoardTable from "@/components/lead-boards/LeadsTable";
import CreateLeadItemSidebar from "@/components/lead-boards/CreateLeadItemSidebar";
import EditLeadItemSidebar from "@/components/lead-boards/EditLeadItemSidebar";
export default {
  name: "LeadsDashboard",
  components: {
    EditLeadItemSidebar,
    CreateLeadItemSidebar,
    LeadBoardTable,
    SearchBar,
  },
  data() {
    return {
      selectedItems: [],
      currentPage: 1,
      search: "",
      onlyReadLead: false,
      isLeadBoard: false,
      fetching: false,
      isLoading: false,
    };
  },
  created() {
    this.$store.getters["userPermissions"].includes("lead_board.read") ||
    this.$store.getters["userPermissions"].includes("lead_board.member")
      ? this.$store.commit("leadTable/SET_CURRENT_BOARD", "lead-boards")
      : this.$store.commit("leadTable/SET_CURRENT_BOARD", "lead-forwards");
    this.fetchLeadData();
  },
  computed: {
    currentBoardTitle() {
      return this.$store.state.leadTable.currentBoard === "lead-boards"
        ? "Lead board"
        : "Lead forwarding";
    },
    currentBoard() {
      return this.$store.state.leadTable.currentBoard;
    },
    status() {
      return this.$store.state.status;
    },
    totalRows() {
      return this.$store.state.leadBoards.totalRows;
    },
    perPage() {
      return this.$store.state.perPage;
    },
    multipleDeleteButtonName() {
      return this.selectedItems.length ? `(${this.selectedItems.length})` : "";
    },
    permissionPrefix() {
      return this.isLeadBoard ? "lead_board" : "lead_forward";
    },
  },
  watch: {
    status() {
      this.currentPage = 1;
    },
  },
  methods: {
    fetchLeadData() {
      this.isLeadBoard =
        this.$store.state.leadTable.currentBoard === "lead-boards";
      this.fetchLeadItems();
      this.fetchLeadItemsMeta();
    },
    createNewBoard() {
      this.$store.commit("leadBoards/SET_SHOWN_SIDEBAR", "create");
      this.$root.$emit("bv::toggle::collapse", "createLeadItemSidebar");
    },
    searchChanged(value) {
      this.search = value;
      this.currentPage = 1;
      this.fetchLeadItems();
    },
    selectItems(event) {
      this.$set(this, "selectedItems", event);
    },
    async fetchLeadItems() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}?page=${
            this.currentPage
          }&perPage=${this.perPage}${
            this.status !== "Active" ? "&isDisabled=1" : ""
          }${this.search ? `&search=${this.search}` : ""}`
        );
        this.$store.commit("leadBoards/SET_TOTAL_ROWS", res.data.total);
        this.$store.commit("leadBoards/SET_LEAD_ITEMS", res.data.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Lead items fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
        this.$refs.leadBoardTable
          ? (this.$refs.leadBoardTable.$children[0].$el.scrollTop = 0)
          : null;
      }
    },
    async fetchLeadItemsMeta() {
      try {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}/meta`
        );
        this.$store.commit("leadBoards/SET_LEAD_META", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Lead items meta fetching failed",
          variant: "danger",
          solid: true,
        });
      }
    },

    async deleteSelectedItem(item) {
      const checkIfArray = Array.isArray(item);
      let conf = confirm(
        `Are you sure to delete ${
          checkIfArray
            ? `multiple items (${item.length})`
            : `item: ${item.title}`
        }`
      );

      if (conf) {
        this.isLoading = true;
        try {
          await this.deleteRequest(checkIfArray, item);

          this.$bvToast.toast("Success", {
            title: "Delete was successful",
            variant: "success",
            solid: true,
          });

          await this.fetchLeadItems();
          this.$set(this, "selectedItems", []);
          this.$set(this.$refs.leadBoardTable, "selectedItemsId", []);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.response.data.message, {
            title: `${this.currentBoardTitle} item delete failed`,
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteRequest(checkIfArray, leadItem) {
      if (!checkIfArray) {
        return await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}/${leadItem.id}`
        );
      } else {
        const data =
          this.currentBoard === "lead-boards"
            ? { leadBoards: leadItem }
            : { leadForwards: leadItem };
        return await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/${this.currentBoard}/bulk-delete`,
          data
        );
      }
    },
  },
};
</script>
