<template>
  <div class="col-lg-3 col-6 px-0 text-break fu-deal__column">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Questionnaire</h5>
    <lead-board-email-questionnaire-modal id="emailQuestionnaireModal" />

    <validation-provider
      tag="div"
      name="questionnaire"
      v-slot="{ errors }"
      class="col-12 px-0 pr-lg-3 pr-0 mt-5"
    >
      <p class="w-100 mb-0 text-capitalize">Assigned questionnaire</p>
      <multiselect
        v-model="selectedQuestionnaire"
        :options="questionnaires"
        :loading="isLoading"
        label="name"
        track-by="name"
        placeholder="Select questionnaire"
        class="multiselect mt-2"
        :class="{
          multiselect__disabled: isLoading,
        }"
        :disabled="isLoading"
        @search-change="searchValue = $event"
      >
        <template slot="placeholder"> Select questionnaire</template>

        <template slot="option" slot-scope="props">
          <span class="kt-select-item">{{ props.option.name }}</span>
        </template>

        <template slot="noResult">
          <span>No country found: "{{ searchValue }}" </span>
        </template>
      </multiselect>
      <span class="text-danger">{{ errors[0] }}</span>
    </validation-provider>

    <div class="col-12 px-0 pr-3 mt-4" v-if="selectedQuestionnaire">
      <div
        v-for="(question, index) in questionnaireData.questions"
        :key="`question - ${index}`"
        class="col-12 px-0"
      >
        <div class="d-flex flex-column col-12 px-0 mb-4">
          <h6 class="mb-2">{{ question.text }} ?</h6>

          <validation-provider
            v-if="question.typeId === 1"
            tag="div"
            :name="`question ${index + 1}`"
            rules="required|max:400"
            v-slot="{ errors }"
            class="col-12 px-0"
          >
            <textarea
              v-model="question.answers[0].text"
              type="text"
              name="question"
              maxlength="400"
              placeholder="Write an answer"
              class="fu-login__input fu-input__textarea"
              :disabled="isLoading"
            />
            <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
          </validation-provider>

          <div
            v-else-if="question.typeId === 2"
            v-for="(answer, index2) in question.answers"
            :key="`binary - ${index2}`"
            class="d-flex flex-column col-12 p-0 pl-3"
          >
            <b-form-checkbox
              v-model="answer.isSubmitted"
              :checked="answer.isSubmitted"
              :value="true"
              class="mb-2 fu-radio"
              @change="selectBinaryAnswer(index, index2)"
              ><span class="fu-permission__label">{{
                answer.text
              }}</span></b-form-checkbox
            >
          </div>

          <div
            v-else
            v-for="(answer, index2) in question.answers"
            :key="`answer - ${index2}`"
            class="d-flex flex-column col-12 p-0 pl-3"
          >
            <b-form-checkbox
              v-model="answer.isSubmitted"
              :value="true"
              class="text-capitalize mb-2"
              ><span class="fu-permission__label">{{
                answer.text
              }}</span></b-form-checkbox
            >
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center">
        <b-button
          class="btn fu-search-bar__create-button fu-sidebar__button-save m-2 p-3"
          @click="openEmailQuestionnaireModal"
          :disabled="isUpdating"
          >Email questionnaire results
          <i v-if="isUpdating" class="fas fa-spinner fa-pulse"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import LeadBoardEmailQuestionnaireModal from "@/components/lead-board/deal/modals/LeadBoardEmailQuestionnaireModal";

export default {
  name: "LeadBoardDealQuestionnaire",
  components: { LeadBoardEmailQuestionnaireModal },
  data() {
    return {
      searchValue: "",
      isLoading: false,
      firstTime: false,
      isUpdating: false,
    };
  },
  computed: {
    questionnaires() {
      return this.$store.state.questionnaire.questionnaires;
    },
    dealQuestioners() {
      return this.$store.state.columnDeal.deal.questionnaires;
    },
    questionnaireData() {
      return this.$store.state.columnDeal.questionnaireData;
    },
    selectedQuestionnaire: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"](
          "activeQuestionnaire"
        );
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "activeQuestionnaire",
          fieldValue,
        });
      },
    },
  },
  watch: {
    selectedQuestionnaire(value) {
      if (value) {
        let data = this.dealQuestioners.find(({ id }) => value.id === id);
        if (data) this.$store.commit("columnDeal/SET_QUESTIONNAIRE_DATA", data);
        else {
          data = this.questionnaires.find(({ id }) => value.id === id);
          this.$store.commit("columnDeal/SET_QUESTIONNAIRE_DATA", data);
        }
      }
    },
    questionnaireData: {
      deep: true,
      handler(value) {
        this.$store.commit(
          "columnDeal/SET_QUESTIONNAIRE_DATA",
          value ? value : { questions: [] }
        );
        if (this.firstTime) this.questionnaireAutoSave(value);
        this.firstTime = true;
      },
    },
  },
  methods: {
    questionnaireAutoSave: lodash.debounce(async function (value) {
      this.isUpdating = true;
      try {
        await this.$axios.put(
          `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.$route.params.dealId}/questionnaire`,
          value
        );
      } catch (error) {
        console.error(error);
        this.$bvToast.toast("Questionnaire was not auto saved", {
          title: "Questionnaire auto save",
          variant: "danger",
          solid: true,
        });
      }
      this.isUpdating = false;
    }, 500),

    selectBinaryAnswer(questionIndex, answerIndex) {
      this.$store.commit("columnDeal/CHANGE_QUESTIONNAIRE_BOOLEAN", {
        questionIndex,
        answerIndex,
      });
    },
    openEmailQuestionnaireModal() {
      this.$bvModal.show("emailQuestionnaireModal");
    },
  },
};
</script>
