<template>
  <main
    class="d-flex flex-column fu-login"
    :class="[
      iFrame ? 'p-0' : 'container align-items-center justify-content-center',
    ]"
  >
    <div
      class="d-flex flex-wrap justify-content-center card col-lg-7 col-md-10 col-12 fu-login__wrapper"
      :class="[
        iFrame
          ? 'px-3 mt-0 py-0 fu-login__wrapper-rectangle'
          : 'px-5 mt-4 py-3',
      ]"
    >
      <div class="d-flex flex-column align-items-center card-body px-0">
        <div class="position-relative text-center mb-4">
          <login-header v-if="showLogo"></login-header>

          <p
            class="text-uppercase mb-0 fu-login__wrapper-title"
            :class="[
              iFrame
                ? 'fu-login__wrapper-title-small'
                : 'fu-login__wrapper-title-big',
            ]"
          >
            {{ title }}
          </p>
        </div>

        <h6>{{ subtitle }}</h6>

        <slot name="body"></slot>

        <div
          class="d-flex flex-column text-center p-0 mt-4"
          :class="[iFrame ? 'col-12' : 'col-6']"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LoginHeader from "@/components/login/LoginHeader";
export default {
  name: "LoginTemplate",
  components: { LoginHeader },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    iFrame: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
