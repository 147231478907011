import axios from "axios";

const $axios = axios.create({ withCredentials: true });

export function get(url, data) {
  return $axios.get(url, data);
}

export function put(url, data) {
  return $axios.put(url, data);
}
