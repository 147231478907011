<template>
  <login-template
    title="Oddaj povpraševanje"
    subtitle=""
    :show-logo="false"
    :i-frame="true"
  >
    <template #body>
      <validation-observer
        ref="dealDetailsForm"
        tag="div"
        class="d-flex flex-wrap col-12 px-0 m-0 mt-3 fu-deal__column-wrapper"
      >
        <lead-board-deal-form
          :is-loading="isLoading"
          :iframe="true"
          form-class="d-flex flex-wrap col-12 px-0 mb-4"
        ></lead-board-deal-form>
      </validation-observer>
    </template>

    <template #footer>
      <fu-button
        id="submitInquiry"
        title="Oddaj povpraševanje"
        :is-loading="isLoading"
        class="w-100 pl-3"
        @click="createDeal"
      ></fu-button>
    </template>
  </login-template>
</template>

<script>
import LeadBoardDealForm from "@/components/lead-board/gadgets/LeadBoardDealForm";
import LoginTemplate from "@/components/login/LoginTemplate";

export default {
  name: "LeadBoardDealPublic",
  components: { LoginTemplate, LeadBoardDealForm },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    createDeal() {
      this.$refs.dealDetailsForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/public/lead-boards/${this.$route.params.id}/deals`,
              this.$store.state.columnDeal.deal
            );
            await this.$store.commit("columnDeal/RESET_DEAL_DATA", {});
            this.$refs.dealDetailsForm.reset();
            this.$bvToast.toast("Povpraševanje je bilo oddano uspešno", {
              title: "Oddano povpraševanje",
              variant: "success",
              solid: true,
            });
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Povpraševanje ni bilo uspešno oddano", {
              title: "Neuspešno oddano povpraševanje",
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
