<template>
  <div id="app">
    <navigation-bar v-if="showNavigation"></navigation-bar>
    <two-factor-auth-modal></two-factor-auth-modal>
    <div class="mainDiv"><router-view /></div>
  </div>
</template>

<script>
import TwoFactorAuthModal from "@/components/login/modals/TwoFactorAuthModal";
import NavigationBar from "@/components/NavigationBar";

export default {
  components: { NavigationBar, TwoFactorAuthModal },
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
    showNavigation() {
      return !(
        this.user === null ||
        (this.user.has2fa && !this.user.secretSubmitted)
      );
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index.scss";
@import "~@fortawesome/fontawesome-pro/css/all.css";
@import "./assets/scss/index";

.mainDiv {
  height: calc(100vh - 70px);
}
</style>
