<template>
  <b-row class="p-2">
    <deals-by-creator />
    <deals-by-lead-board />
  </b-row>
</template>

<script>
import DealsByCreator from "@/components/analytics/DealsByCreator";
import DealsByLeadBoard from "@/components/analytics/DealsByLeadBoard";
export default {
  name: "CreatedDeals",
  components: {
    DealsByLeadBoard,
    DealsByCreator,
  },
};
</script>

<style scoped></style>
