<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-2">
    <b-col class="col-6">
      <h5>Top lead forwards</h5>
      <ol type="1">
        <li v-for="leadForward in leadForwards" :key="leadForward.id">
          {{ leadForward.title }} ({{ leadForward.counter }}) ->
          {{ leadForward.company.name }}
        </li>
      </ol>
    </b-col>
    <b-col class="col-6">
      <h5>Top companies</h5>
      <ol type="1">
        <li v-for="company in companies" :key="company.id">
          {{ company.name }} ({{ company.sentLeads }})
        </li>
      </ol>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "LeadForwardingAnalytics",
  computed: {
    leadForwards() {
      return this.$store.state.analytics.leadForwardsData.leadForwards;
    },
    companies() {
      return this.$store.state.analytics.leadForwardsData.companies;
    },
  },
};
</script>

<style scoped></style>
