<template>
  <b-modal
    id="setMeetingModal"
    ref="setMeetingModal"
    centered
    content-class="fu-modal"
    header-class="fu-modal__header fu-modal__submit-header"
    body-class="fu-modal__body"
    footer-class="fu-modal__footer"
    @hidden="closeModal"
  >
    <template #modal-header>
      <div><h3>Set a meeting</h3></div>
    </template>
    <div class="d-flex">
      <validation-observer v-if="step === 2" ref="setMeeting">
        <validation-provider
          v-for="(email, index) in emails"
          :key="index"
          tag="div"
          name="emails"
          v-slot="{ errors }"
          rules="required|email"
          class="col-12 px-0 pr-lg-3"
        >
          <div class="d-flex align-items-center">
            <b-input
              class="m-2 w-100 fu-login__input"
              v-model="emails[index]"
              placeholder="Email.."
            >
            </b-input>
            <i
              @click="removeEmail(index)"
              class="fas fa-trash fu-contact__button"
            ></i>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
        <div>
          <button
            class="d-flex justify-content-center w-100 mt-2 fu-contact__button"
            @click="addEmailAddress"
            :disabled="isLoading"
          >
            <span><i class="ml-3 mr-2 fas fa-plus"></i>Add address</span>
          </button>
        </div>
      </validation-observer>
      <validation-observer v-else-if="step === 1" ref="setMeeting">
        <validation-provider
          tag="div"
          name="dateTime"
          v-slot="{ errors }"
          rules="required"
          class="col-12 px-0 pr-lg-3"
        >
          <date-picker v-model="selectedDateTime" mode="dateTime" is24hr />
          <span class="text-danger d-block">{{ errors[0] }}</span>
        </validation-provider>
      </validation-observer>
    </div>
    <template #modal-footer>
      <fu-button
        v-if="step === 1"
        @click="changeStep(2)"
        title="Invite users"
        id="inviteUsersButton"
        :disabled="isLoading"
      >
      </fu-button>
      <fu-button
        v-if="step === 2"
        @click="changeStep(1)"
        title="Back"
        id="backButton"
        :disabled="isLoading"
      >
      </fu-button>
      <fu-button
        ref="setMeetingButton"
        title="Save"
        id="setMeetingButton"
        @click="saveMeeting"
        :is-loading="isLoading"
        :disabled="isLoading"
      >
        <template #button-icon><i class="fas fa-check mr-2"></i></template>
      </fu-button>
    </template>
  </b-modal>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  name: "setMeetingModal",
  components: {
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      step: 1,
      emails: [],
      selectedDateTime: null,
    };
  },
  computed: {
    meetingDateTime: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"](
          "meetingDateTime"
        );
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "meetingDateTime",
          fieldValue,
        });
      },
    },
  },
  methods: {
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async saveMeeting() {
      this.$refs.setMeeting.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.$route.params.dealId}/set-meeting`,
              {
                emails: this.emails,
                meetingDateTime: this.$moment(this.selectedDateTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              }
            );

            this.$bvToast.toast("Meeting was successfully saved", {
              title: `Success`,
              variant: "success",
              solid: true,
            });

            this.meetingDateTime = this.selectedDateTime;
            await this.$store.commit("columnDeal/ADD_DEAL_COMMENT", res.data);

            this.closeModal();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast("Saving a meeting failed", {
              title: `Failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },

    addEmailAddress() {
      this.emails.push(null);
    },
    changeStep(step) {
      this.$refs.setMeeting.validate().then(async (success) => {
        if (success) this.step = step;
      });
    },
    closeModal() {
      this.emails = [];
      this.selectedDateTime = null;
      this.step = 1;
      this.$refs.setMeetingModal.hide();
    },
  },
};
</script>
