<template>
  <div class="d-flex flex-wrap justify-content-center">
    <label
      class="d-flex flex-wrap justify-content-center col-12 p-0 mt-4 mb-5 qr-modal__inputs"
    >
      <input
        v-for="index in 6"
        :key="index"
        ref="input"
        type="text"
        maxlength="1"
        autocomplete="off"
        class="col-1 px-0 mr-2 qr-modal__inputs-input"
        @keyup.delete="goBack(index - 1)"
        @input="enterNumber(index - 1, $event)"
        @keydown.enter="$emit('submit')"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: "CodeInput",
  data() {
    return {
      secret: "",
    };
  },
  watch: {
    secret() {
      if (this.secret.match(/^[0-9]+$/)) {
        this.$emit("secret", this.secret);
      }
    },
  },
  methods: {
    enterNumber(index, e) {
      if (e.data && this.$refs.input.length !== index + 1) {
        this.$refs.input[index + 1].focus();
      }
      this.secret = this.$refs.input.map((e) => e.value).join("");
    },
    goBack(index) {
      if (index > 0) this.$refs.input[index - 1].focus();
    },
  },
};
</script>
