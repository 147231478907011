<template>
  <div ref="dealDetailsWrapper" class="col-lg-3 col-6 px-0 fu-deal__column">
    <h5 class="pb-3 text-capitalize fu-deal__column-title">Deal details</h5>

    <validation-observer
      ref="dealDetailsForm"
      tag="div"
      class="d-flex flex-wrap col-12 pl-0 mb-4 fu-deal__column-wrapper"
    >
      <lead-board-deal-form
        :is-loading="isLoading"
        form-class="d-flex flex-wrap col-12 px-0 mb-4"
      ></lead-board-deal-form>

      <div
        v-for="(item, index) in customFields"
        :key="index"
        class="d-flex flex-wrap col-12 px-0 mt-3"
      >
        <div
          class="d-flex flex-wrap align-items-center justify-content-between w-100"
        >
          <span class="font-weight-bold">Custom field {{ index + 1 }} *</span>

          <button
            class="fu-button__transparent"
            @click="deleteCustomField(index)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
        <br />
        <validation-provider
          tag="div"
          :name="`title ${index + 1}`"
          rules="required"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pr-lg-3 pr-0 mb-0"
        >
          <input
            v-model="item.title"
            type="text"
            name="title"
            required
            placeholder="Title"
            class="fu-login__input"
            :disabled="isLoading"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>

        <validation-provider
          tag="div"
          :name="`value ${index + 1}`"
          rules="required"
          v-slot="{ errors }"
          class="col-lg-6 col-12 px-0 pl-lg-3 pl-0 mb-0"
        >
          <input
            v-model="item.value"
            type="text"
            name="custom field"
            required
            placeholder="Value"
            class="fu-login__input"
            :disabled="isLoading"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </div>
    </validation-observer>

    <button
      class="d-flex justify-content-center w-100 mb-4 fu-contact__button"
      @click="addCustomField"
      :disabled="isLoading"
    >
      <span><i class="far fa-plus mr-2"></i>Add Custom Field</span>
    </button>
  </div>
</template>

<script>
import LeadBoardDealForm from "@/components/lead-board/gadgets/LeadBoardDealForm";
export default {
  name: "LeadBoardDealDetails",
  components: { LeadBoardDealForm },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    organization() {
      return this.$store.state.columnDeal.dealMeta.clients;
    },
    currencies() {
      return this.$store.state.columnDeal.dealMeta.currencies;
    },
    customFields: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"](
          "customFields"
        );
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "customFields",
          fieldValue,
        });
      },
    },
  },
  methods: {
    deleteCustomField(index) {
      const conf = confirm(
        `Do you really want to delete custom field ${index + 1} ?`
      );
      if (conf) this.customFields.splice(index, 1);
    },
    addCustomField() {
      this.customFields.push({
        value: "",
        title: "",
      });
      this.$nextTick(() => {
        const wrapper = this.$refs.dealDetailsWrapper;
        wrapper.scrollTo(0, wrapper.scrollHeight);
      });
    },
  },
};
</script>
