<template>
  <div class="d-flex align-items-center justify-content-between w-100 py-4">
    <set-meeting-modal></set-meeting-modal>
    <div class="d-flex align-items-center">
      <button
        class="fu-button__transparent px-0"
        :disabled="isLoading"
        @click="discardChanges"
      >
        <i
          class="far fa-arrow-alt-circle-left mr-4 fu-navigation__arrow-back"
        ></i>
      </button>
      <h3 class="mb-0">{{ dealTitle }}</h3>
    </div>
    <div class="d-flex justify-content-end align-items-center fu-deal__footer">
      <button
        class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
        :disabled="isLoading"
        @click="openSetMeetingModal"
      >
        <span v-if="!dealMeetingDateTime"
          ><i class="fas fa-calendar-alt mr-2"></i>
          Set a Meeting
        </span>
        <span v-else
          ><i class="fas fa-calendar-alt mr-2"></i> Meeting is scheduled for
          {{ dealMeetingDateTime }}
        </span>
      </button>
      <button
        class="btn fu-search-bar__create-button fu-sidebar__button-save mr-3 p-3"
        @click="saveChanges"
        :disabled="isLoading"
      >
        <span><i :class="`far fa-edit mr-2`"></i>Save</span>
      </button>

      <button
        class="btn btn-primary p-3"
        @click="discardChanges"
        :disabled="isLoading"
      >
        <span><i class="far fa-times mr-2"></i>Cancel</span>
      </button>
    </div>
  </div>
</template>

<script>
import SetMeetingModal from "@/components/lead-board/deal/modals/SetMeetingModal";

export default {
  name: "LeadBoardDealHeader",
  components: { SetMeetingModal },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dealName() {
      return this.$store.state.columnDeal.deal.title;
    },
    dealMeetingDateTime() {
      return this.$store.state.columnDeal.deal.meetingDateTime
        ? this.$moment(
            this.$store.state.columnDeal.deal.meetingDateTime
          ).format("DD. MM. YYYY HH:mm")
        : false;
    },
    dealTitle() {
      return `View Deal ${this.dealName ? `- ${this.dealName}` : ""}`;
    },
  },
  methods: {
    async saveChanges() {
      this.$emit("saveChanges");
    },
    openSetMeetingModal() {
      this.$bvModal.show("setMeetingModal");
    },
    async discardChanges() {
      const conf = confirm("Do you really want discard all changes?");
      if (conf)
        await this.$router.push({
          name: "LeadBoard",
          params: { id: this.$route.params.id },
        });
    },
  },
};
</script>
