<template>
  <div
    class="col-12 px-0 mb-3 mt-4 pt-3 fu-deal__column-wrapper-activity fu-border__lightgrey-top"
  >
    <div
      ref="dealCommentsWrapper"
      class="col-12 px-0 mb-3 fu-deal__column-activity"
    >
      <h6 class="text-capitalize font-weight-bold fu-deal__column-title">
        Comments and logs:
      </h6>
      <p
        v-if="comments ? !comments.length : false"
        class="d-flex align-items-center justify-content-center pt-5 fu-deal__column-activity__empty"
      >
        There are no comments on list
      </p>
      <ul
        v-for="(value, index) in comments"
        :key="index"
        class="d-flex flex-wrap justify-content-between col-12 p-0 pl-4"
        :class="{ 'mt-4': index === 0 }"
      >
        <li class="text-break col-10 px-0">
          <span class="fu-deal__column-activity__user">
            {{ `${value.user.firstName} ${value.user.lastName}: ` }}</span
          >
          {{ value.text }}
        </li>
        <p class="col-2 px-0 mb-0 pr-2 fu-deal__column-activity__date">
          {{ commentDateFormatter(value.createdAt) }}
        </p>
      </ul>
    </div>
    <div
      class="d-flex flex-wrap justify-content-between align-items-start col-12 px-0"
    >
      <validation-observer
        ref="commentsActivityForm"
        class="col-8 px-0"
        :disabled="true"
      >
        <validation-provider
          tag="div"
          name="comment"
          rules="required"
          v-slot="{ errors }"
        >
          <input
            v-model="newCommentText"
            type="text"
            placeholder="Add comment"
            class="mr-3 fu-deal__column-activity__input"
            :disabled="isLoading"
            @keyup.enter="addNewComment"
          />
          <span class="text-danger pt-0 pb-0">{{ errors[0] }}</span>
        </validation-provider>
      </validation-observer>

      <button
        class="col-3 px-0 fu-deal__column-activity__button"
        :disabled="isLoading"
        @click="addNewComment"
      >
        Add comment
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadBoardDealActivityComments",
  data() {
    return {
      isLoading: false,
      newCommentText: null,
    };
  },
  computed: {
    comments: {
      get() {
        return this.$store.getters["columnDeal/getDealFieldData"]("comments");
      },
      set(fieldValue) {
        this.$store.commit("columnDeal/CHANGE_DEAL_FIELD", {
          fieldName: "comments",
          fieldValue,
        });
      },
    },
  },
  methods: {
    commentDateFormatter(date) {
      return `${new Date(date).toLocaleDateString()} ${date
        .split(" ")[1]
        .slice(0, 5)}`;
    },
    async addNewComment() {
      this.$refs.commentsActivityForm.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const res = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}/api/lead-boards/${this.$route.params.id}/deals/${this.$route.params.dealId}/comments`,
              { text: this.newCommentText }
            );

            await this.$store.commit("columnDeal/ADD_DEAL_COMMENT", res.data);
            this.$bvToast.toast("Deal comment added", {
              title: `Success`,
              variant: "success",
              solid: true,
            });

            this.$nextTick(() => {
              this.$refs.dealCommentsWrapper.scrollTo(0, 0);
            });
            this.newCommentText = null;
            this.$refs.commentsActivityForm.reset();
          } catch (error) {
            console.error(error);
            this.$bvToast.toast(error.message, {
              title: `Adding comment failed`,
              variant: "danger",
              solid: true,
            });
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>
