<template>
  <div>
    <h5>On LeadBoard</h5>
    <ol type="1">
      <li v-for="leadBoard in dealsByLeadBoard" :key="leadBoard.id">
        {{ leadBoard.title }} ({{ leadBoard.createdDealsCount }})
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "DealsByLeadBoard",
  data() {
    return {
      dateRange: null,
    };
  },
  computed: {
    dealsByLeadBoard() {
      return this.$store.state.analytics.dealsByLeadBoard;
    },
  },
};
</script>

<style scoped></style>
