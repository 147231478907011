import axios from "axios";
import router from "../router/index";

export async function setCsrfCookie() {
  return await axios.get(process.env.VUE_APP_API_URL + "/sanctum/csrf-cookie", {
    withCredentials: true,
  });
}

export async function setUserData() {
  return await axios.get(process.env.VUE_APP_API_URL + "/api/auth/me", {
    withCredentials: true,
  });
}

export function checkUserPermissions(user) {
  let permissions = [];

  permissions = user.permissions
    .map(({ name }) => {
      if (name.includes("read") || name.includes("member"))
        return name.split(".")[0].split("_")[0];
    })
    .filter((el) => {
      return el !== undefined;
    });

  let defaultRoute = router
    .getRoutes()
    .find(({ name }) => name === "NoPermissions");
  if (permissions.length > 0) {
    defaultRoute = router
      .getRoutes()
      .find(({ meta }) => meta.permissionName === permissions[0]);
  }

  return {
    permissions,
    defaultRoute,
  };
}
