<template>
  <div class="d-flex flex-wrap col-12 p-0 mt-2">
    <b-table
      table-class="fu-table"
      bordered
      hover
      sticky-header="55vh"
      :items="companiesWithoutLeadBoards"
      :fields="fields"
    >
      <template #cell(action)>
        <i
          v-b-tooltip.hover
          data-toggle="tooltip"
          data-placement="top"
          title="Edit"
          class="far fa-edit fu-search-bar__status-tooltip fu-table__tooltip"
          @click="goToLeadBoards"
        ></i>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "CompaniesWithoutLeadBoardsTable",
  data() {
    return {
      fields: [
        {
          key: "name",
          sortable: false,
          label: "Name",
        },
        {
          key: "email",
          sortable: false,
          label: "Email",
        },
        {
          key: "phone",
          sortable: false,
          label: "Phone",
        },
        {
          key: "action",
          sortable: false,
          label: "Edit",
        },
      ],
    };
  },
  computed: {
    companiesWithoutLeadBoards() {
      return this.$store.state.analytics.companiesWithoutLeadBoards;
    },
  },
  methods: {
    goToLeadBoards() {
      this.$router.push({
        name: "LeadBoardDashboard",
      });
    },
  },
};
</script>

<style scoped></style>
