const state = {
  leadBoardTableFields: [
    {
      key: "select",
      sortable: false,
      label: "",
    },
    {
      key: "title",
      sortable: true,
    },
    {
      key: "company",
      sortable: true,
      formatter: (value, key, item) => {
        return item.company ? item.company.name : "";
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "description",
      sortable: false,
    },
    {
      key: "apiEnabled",
      sortable: true,
      label: "API enabled",
      formatter: (value, key, item) => {
        return item.apiEnabled ? "Enabled" : "Disabled";
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "facebookFormTitle",
      sortable: true,
      label: "Lead form",
    },
    {
      key: "members",
      sortable: false,
      formatter: (value, key, item) => {
        let contactUsers = "";
        contactUsers += item.users
          .slice(0, 3)
          .map((value) => {
            return `${value.user.firstName} ${value.user.lastName}`;
          })
          .join(", ");
        return item.users.slice(0, 3).length === 3
          ? `${contactUsers}, ...`
          : contactUsers;
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ],
  leadForwardingTableFields: [
    {
      key: "select",
      sortable: false,
      label: "",
    },
    {
      key: "title",
      sortable: true,
    },
    {
      key: "company",
      sortable: true,
      formatter: (value, key, item) => {
        return item.company ? item.company.name : "";
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "description",
      sortable: false,
    },
    {
      key: "facebookFormTitle",
      sortable: true,
      label: "Lead form",
    },
    {
      key: "forwardEmail",
      sortable: false,
      label: "Forward email to",
      formatter: (value, key, item) => {
        let concatEmails = "";
        concatEmails += item.emails
          .slice(0, 3)
          .map((value) => {
            return value;
          })
          .join(", ");
        return item.emails.slice(0, 3).length === 3
          ? `${concatEmails}, ...`
          : concatEmails;
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "enabled",
      sortable: true,
      formatter: (value, key, item) => {
        return item.isActive ? "Enabled" : "Disabled";
      },
      sortByFormatted: true,
      filterByFormatted: true,
    },
    {
      key: "counter",
      sortable: true,
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ],
  currentBoard: "lead-boards",
};

const mutations = {
  SET_CURRENT_BOARD(state, board) {
    state.currentBoard = board;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
