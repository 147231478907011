<template>
  <main ref="companiesDashboard" class="d-flex flex-wrap mb-5 h-100">
    <b-overlay :show="fetching" class="d-flex flex-wrap col-12">
      <search-bar
        title="Create new companies, add and remove contacts"
        button-title="Create company"
        :selected-items="selectedCompanies"
        :is-loading="isLoading"
        multiple-delete-button-id="kt-button-delete-selected-company"
        permissionName="companies.write"
        delete-permission-name="companies.delete"
        @createNew="isNewCompany"
        @changeStatus="fetchCompanies"
        @searchChanged="searchChanged($event)"
        @deleteSelectedItem="deleteSelectedCompany($event)"
      >
      </search-bar>

      <companies-table
        ref="companiesTable"
        @selectedCompanies="selectCompanies($event)"
        @deleteCompany="deleteSelectedCompany($event)"
        @onlyReadCompany="onlyReadCompany = $event"
        @enabledApiConnections="enabledApiConnections = $event"
      ></companies-table>
    </b-overlay>

    <create-company-sidebar
      @refreshCompanies="fetchCompanies"
    ></create-company-sidebar>

    <edit-company-sidebar
      :only-read-company="onlyReadCompany"
      :enabled-api-prop="enabledApiConnections"
      @refreshCompanies="fetchCompanies"
    ></edit-company-sidebar>

    <!-- START OF PAGINATION -->
    <div class="d-flex align-items-end flex-column col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        next-class="next-page"
        prev-class="prev-page"
        last-class="last-page"
        first-class="first-page"
        size="lg"
        class="mb-0 mt-auto p-3"
        col-12
        @input="fetchCompanies"
      ></b-pagination>
    </div>
    <!-- END OF PAGINATION -->
  </main>
</template>

<script>
import SearchBar from "@/components/gadgets/SearchBar";
import CompaniesTable from "@/components/companies/CompaniesTable";
import CreateCompanySidebar from "@/components/companies/company/CreateCompanySidebar";
import EditCompanySidebar from "@/components/companies/company/EditCompanySidebar";
export default {
  name: "CompaniesDashboard",
  components: {
    EditCompanySidebar,
    CreateCompanySidebar,
    CompaniesTable,
    SearchBar,
  },
  data() {
    return {
      selectedCompanies: [],
      onlyReadCompany: false,
      enabledApiConnections: false,
      currentPage: 1,
      search: "",
      fetching: false,
      isLoading: false,
    };
  },
  created() {
    this.fetchCompanies();
    this.fetchCompaniesMeta();
  },
  computed: {
    status() {
      return this.$store.state.status;
    },
    totalRows() {
      return this.$store.state.companies.totalRows;
    },
    perPage() {
      return this.$store.state.perPage;
    },
  },
  watch: {
    status() {
      this.currentPage = 1;
    },
  },
  methods: {
    isNewCompany() {
      this.$root.$emit("bv::toggle::collapse", "createCompany");
    },
    searchChanged(value) {
      this.search = value;
      this.currentPage = 1;
      this.fetchCompanies();
    },
    selectCompanies(event) {
      this.$set(this, "selectedCompanies", event);
    },
    async fetchCompanies() {
      try {
        this.fetching = true;
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/companies?page=${
            this.currentPage
          }&perPage=${this.perPage}${
            this.status !== "Active" ? "&isDisabled=1" : ""
          }${this.search ? `&search=${this.search}` : ""}`
        );
        this.$store.commit("companies/SET_TOTAL_ROWS", res.data.total);
        this.$store.commit("companies/SET_COMPANIES", res.data.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Companies fetching failed",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.fetching = false;
        this.$refs.companiesTable
          ? (this.$refs.companiesTable.$children[0].$el.scrollTop = 0)
          : null;
      }
    },
    async fetchCompaniesMeta() {
      try {
        const res = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/api/companies/meta`
        );
        this.$store.commit("companies/SET_COMPANIES_META", res.data);
      } catch (error) {
        console.error(error);
        this.$bvToast.toast(error.response.data.message, {
          title: "Companies meta fetching failed",
          variant: "danger",
          solid: true,
        });
      }
    },
    async deleteSelectedCompany(company) {
      const checkIfArray = Array.isArray(company);
      let conf = confirm(
        `Are you sure to delete ${
          checkIfArray
            ? `multiple companies (${company.length})`
            : `company: ${company.email}`
        }`
      );
      if (conf) {
        this.isLoading = true;
        try {
          await this.deleteRequest(checkIfArray, company);

          this.$bvToast.toast("Success", {
            title: "Delete was successful",
            variant: "success",
            solid: true,
          });
          await this.fetchCompanies();
          this.$set(this, "selectedCompanies", []);
          this.$set(this.$refs.companiesTable, "selectedCompaniesId", []);
        } catch (error) {
          console.error(error);
          this.$bvToast.toast(error.response.data.message, {
            title: "Company delete failed",
            variant: "danger",
            solid: true,
          });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteRequest(checkIfArray, company) {
      if (!checkIfArray) {
        return await this.$axios.delete(
          `${process.env.VUE_APP_API_URL}/api/companies/${company.id}`
        );
      } else {
        return await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/api/companies/bulk-delete`,
          { companies: company }
        );
      }
    },
  },
};
</script>
