<template>
  <div>
    <h5>By User</h5>

    <ol type="1">
      <li v-for="creator in dealsByCreator" :key="creator.id">
        {{ creator.firstName + " " + creator.lastName }} ({{
          creator.createdDealsCount
        }})
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "DealsByCreator",
  computed: {
    dealsByCreator() {
      return this.$store.state.analytics.dealsByCreator;
    },
  },
};
</script>

<style scoped></style>
