import Vue from "vue";
import lodash from "lodash";

const state = {
  userData: {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isDisabled: false,
    has2fa: false,
    positionId: null,
    permissions: [],
  },
};

const mutations = {
  SET_USER_DATA(state, user) {
    const permissions = user.permissions.map(({ id }) => id);
    state.userData = lodash.cloneDeep(user);
    state.userData.permissions = lodash.cloneDeep(permissions);
  },
  CHANGE_USER_FIELD(state, { fieldName, fieldValue }) {
    state.userData[fieldName] = fieldValue;
  },
  CHANGE_USER_PERMISSIONS(state, permissionsData) {
    Vue.set(state.userData, "permissions", permissionsData);
  },
  RESET_USER_DATA(state) {
    state.userData = {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      isDisabled: false,
      has2fa: false,
      positionId: null,
      permissions: [],
    };
  },
};

const actions = {};

const getters = {
  getUserFieldData: (state) => (fieldName) => {
    return state.userData[fieldName];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
