var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"d-flex flex-column fu-login",class:[
    _vm.iFrame ? 'p-0' : 'container align-items-center justify-content-center' ]},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center card col-lg-7 col-md-10 col-12 fu-login__wrapper",class:[
      _vm.iFrame
        ? 'px-3 mt-0 py-0 fu-login__wrapper-rectangle'
        : 'px-5 mt-4 py-3' ]},[_c('div',{staticClass:"d-flex flex-column align-items-center card-body px-0"},[_c('div',{staticClass:"position-relative text-center mb-4"},[(_vm.showLogo)?_c('login-header'):_vm._e(),_c('p',{staticClass:"text-uppercase mb-0 fu-login__wrapper-title",class:[
            _vm.iFrame
              ? 'fu-login__wrapper-title-small'
              : 'fu-login__wrapper-title-big' ]},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('h6',[_vm._v(_vm._s(_vm.subtitle))]),_vm._t("body"),_c('div',{staticClass:"d-flex flex-column text-center p-0 mt-4",class:[_vm.iFrame ? 'col-12' : 'col-6']},[_vm._t("footer")],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }