import Vue from "vue";
import lodash from "lodash";

const state = {
  totalRows: 0,
  leadItems: [],
  currentShownSidebar: "create",
  leadMeta: {
    companies: [],
    roles: [],
    users: [],
  },
};

const mutations = {
  SET_LEAD_ITEMS(state, items) {
    state.leadItems = lodash.cloneDeep(items);
  },
  REMOVE_LEAD_ITEM(state, itemData) {
    const index = state.leadItems.findIndex(({ id }) => itemData.id === id);
    state.leadItems.splice(index, 1, itemData);
  },
  SET_LEAD_META(state, leadMeta) {
    Vue.set(state, "leadMeta", leadMeta);
  },
  SET_SHOWN_SIDEBAR(state, value) {
    state.currentShownSidebar = value;
  },
  SET_TOTAL_ROWS(state, totalPaginationRows) {
    Vue.set(state, "totalRows", totalPaginationRows);
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
