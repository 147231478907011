import store from "./store";
import router from "./router";

import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import _ from "lodash";

/* Importing VeeValidate Plugin */
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  setInteractionMode,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import {
  email,
  required,
  max,
  min,
  alpha_spaces,
  numeric,
  regex,
} from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import "vue-moment";
Vue.use(require("vue-moment"));

localize("en", en);
setInteractionMode("eager");

extend("email", email);
extend("required", required);
extend("max", max);
extend("min", min);
extend("alpha_spaces", alpha_spaces);
extend("numeric", numeric);
extend("regex", regex);

/* Import Multiselect component*/
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

/* Import BootstrapVue */
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

/* Importing Custom Components */
import AuthAction from "@/components/gadgets/AuthAction";
import FuButton from "@/components/gadgets/FuButton";
Vue.component("fu-button", FuButton);
Vue.component("auth-action", AuthAction);

Vue.config.productionTip = false;

Vue.prototype.$axios = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_URL,
});

Vue.prototype.$lodash = _;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
