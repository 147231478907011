import lodash from "lodash";

const state = {
  columnId: null,
  rules: [],
};

const mutations = {
  SET_COLUMN_RULES(state, rules) {
    state.rules = lodash.cloneDeep(rules);
  },
  SET_COLUMN_ID(state, id) {
    state.columnId = id;
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
